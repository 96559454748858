<script setup lang="ts">
import { Datamodel, DatamodelsRestService } from 'platform-unit2-api/datamodels';
import { MenuItem } from 'primevue/menuitem';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import useSortable from 'composables/sortable';
import usePagination from 'composables/usePagination/pagination';

import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import EmptyState from '@/general/ui/components/empty-state.vue';
import TableSkeleton from '@/general/ui/components/skeletons/table-skeleton.vue';
import { DataTablePageEvent, DataTableRowClickEvent, DataTableSortEvent } from 'primevue/datatable';
import NameDatamodel from 'retailer/modules/datamodels/components/create-datamodel/name-datamodel.vue';
import DatamodelCreate from 'retailer/modules/datamodels/components/datamodel-create.vue';
import DatamodelEdit from 'retailer/modules/datamodels/components/datamodel-edit.vue';
import RenameDatamodel from 'retailer/modules/datamodels/components/edit-datamodel-name.vue';

import { formatDate } from '@/general/utils/format-date';
import { User } from 'platform-unit2-api/users';
import useDebounce from 'utils/debounce';

const { debounce } = useDebounce();

const store = useStore();
const route = useRoute();

const { page, perPage, onPageChange: $onPageChange, query } = usePagination();
const { sort, onSortChange: $onSortChange } = useSortable();

const menu = ref();
const loading = ref(false);
const datamodels = ref<Datamodel[]>([]);
const total = ref<number>();
const selectedDatamodel = ref<Datamodel>();
let searchCallback: any = undefined;

/** breadcrumbs */

/** Handle display of 'Add datamodel' Dialog */
const showAddDialog = ref(false);

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'datamodels');
const confirmService = new ConfirmService();
const datamodelApi = new DatamodelsRestService();

/** Handle display of 'Add datamodel Name' Dialog */
const showNameDialog = ref(false);

const downloadDatamodelExcel = async (): Promise<void> => {
  if (!selectedDatamodel.value) {
    return;
  }

  await datamodelApi.downloadAttributes(selectedDatamodel.value.id).then((res) => {
    const link = document.createElement('a');

    link.href = URL.createObjectURL(res);
    link.setAttribute('target', '_blank');
    link.setAttribute('download', selectedDatamodel.value?.name ?? '');
    link.click();
  });
};

const menuItems = (): MenuItem[] => [
  {
    label: ts.tModule('datamodel_edit.title'),
    icon: 'mdi mdi-pencil',
    command: () => {
      editDatamodel();
    },
  },
  {
    label: ts.tModule('datamodelRename.title'),
    icon: 'mdi mdi-form-textbox',
    command: () => {
      renameDatamodel();
    },
  },
  {
    label: ts.tModule('datamodel_download.title'),
    icon: 'mdi mdi-tray-arrow-down',
    command: () => {
      downloadDatamodelExcel();
    },
  },
  {
    label: ts.tModule('datamodel_duplicate.title'),
    icon: 'mdi mdi-content-copy',
    command: () => {
      duplicateDatamodel();
    },
  },
  {
    separator: true,
  },
  {
    label: ts.tModule('datamodel_delete.title'),
    icon: 'mdi mdi-delete-outline',
    class: 'delete',
    command: () => {
      confirmDatamodelDelete();
    },
  },
];

const currentUser = computed<User>(() => store.getters['users/currentUser']);

const duplicateDatamodel = async (): Promise<void> => {
  if (!selectedDatamodel.value || !currentUser.value?.workspace) return;
  try {
    await datamodelApi.duplicateDatamodel(selectedDatamodel.value.id, {
      workspace_id: currentUser.value.workspace.id,
      duplicate_export_mappings: false,
      duplicate_import_mappings: false,
    });
    toastService.displaySuccessToast(ts.tModule('datamodel_duplicate.success'));
  } catch {
    toastService.displayErrorToast(ts.tGlobal('unknown'));
  } finally {
    loadAsyncData();
  }
};

const loadAsyncData = async (): Promise<void> => {
  datamodels.value = [];
  loading.value = true;
  if (!sort.value) sort.value = '-id';
  try {
    const response = await datamodelApi.getAll({
      query: query.value,
      page: page.value,
      sortBy: sort.value,
      limit: perPage.value,
    });

    response.data.forEach((element: Datamodel) => {
      element.created_at = formatDate(new Date(element.created_at ?? ''));
      element.updated_at = formatDate(new Date(element.updated_at ?? ''));
    });

    datamodels.value = response.data;
    total.value = response.meta?.total;
  } catch (err) {
    toastService.displayErrorToast(ts.getFailed());
  } finally {
    loading.value = false;
  }
};

const toggleActionMenu = (event: MouseEvent, datamodel: Datamodel) => {
  selectedDatamodel.value = datamodel;
  menu.value.toggle(event);
};

const deleteDatamodel = async (id?: number): Promise<void> => {
  if (!id) return;
  loading.value = true;
  try {
    await datamodelApi.delete(id);
    await loadAsyncData();
    toastService.displaySuccessToast(ts.deleteSuccess());
  } catch (err) {
    toastService.displayErrorToast(ts.deleteFailed());
  } finally {
    loading.value = false;
  }
};

const confirmDatamodelDelete = () => {
  confirmService.confirmDelete({
    callback: () => deleteDatamodel(selectedDatamodel.value?.id),
    group: 'datamodels',
    header: ts.tModule('datamodel_delete_confirmation.title'),
    message: ts.tModule('datamodel_delete_confirmation.subtitle', {
      params: { name: selectedDatamodel.value?.name },
    }),
  });
};

const search = async (searchQuery: string) => {
  if (searchQuery !== undefined) {
    query.value = searchQuery;
  }

  if (searchCallback) {
    searchCallback.cancel();
  }

  searchCallback = debounce(async () => {
    try {
      await loadAsyncData();
    } catch (err: any) {
      ToastService.getInstance().displayErrorToast(ts.searchFailed(searchQuery));
    }
  }, 420);
  searchCallback();
};

function resolveDialogs() {
  if (route.name === 'edit-datamodel') {
    showEditDialog.value = true;
  }

  if (route.name === 'new-datamodel') {
    showNameDialog.value = true;
  }

  if (route.name === 'datamodels') {
    showEditDialog.value = false;
  }
}

watch(() => route, resolveDialogs, {
  deep: true,
});

onMounted(() => {
  loadAsyncData();
  resolveDialogs();
});

const onPageChange = (event: DataTablePageEvent) => {
  $onPageChange(event.page + 1, loadAsyncData);
};

const onSortChange = (event: DataTableSortEvent) => {
  if (event.sortOrder == null) {
    return;
  }

  $onSortChange((event.sortOrder < 0 ? '-' : '') + event.sortField, loadAsyncData);
};

/** Select datamodel to edit */
const showEditDialog = ref(false);
const showRenameDialog = ref(false);

const editDatamodel = (datamodel?: Datamodel) => {
  if (datamodel) selectedDatamodel.value = datamodel;
  showEditDialog.value = true;
};

const renameDatamodel = () => {
  showRenameDialog.value = true;
};

/** Created datamodel Id */
const datamodel = ref<Datamodel>();

const openAttributeDialog = (_datamodel: Datamodel) => {
  datamodel.value = _datamodel;
  showAddDialog.value = true;
};
</script>

<template>
  <section class="flex flex-column h-full pt-3 px-4">
    <IconField icon-position="left" class="my-3">
      <InputIcon class="pi pi-search" />
      <InputText
        :placeholder="ts.tGlobal('search')"
        @update:model-value="(value?: string) => value && search(value)"
      />
    </IconField>

    <TableSkeleton v-if="loading" />

    <!-- #region data table -->
    <DataTable
      v-if="!loading"
      scrollable
      scroll-height="flex"
      :value="datamodels"
      removable-sort
      :row-hover="true"
      :lazy="true"
      :paginator="(total ?? 0) > perPage"
      :rows="perPage"
      :total-records="total"
      :first="(page - 1) * perPage"
      @row-click="(event: DataTableRowClickEvent) => editDatamodel(event.data)"
      @page="(event: DataTablePageEvent) =>onPageChange(event)"
      @sort="(event: DataTableSortEvent ) => onSortChange(event)"
    >
      <Column field="name" header="Name" sortable />
      <Column
        field="created_at"
        :header="ts.tModule('datamodel_info_header.date_created')"
        sortable
      />
      <Column
        field="updated_at"
        :header="ts.tModule('datamodel_info_header.date_updated')"
        sortable
      />
      <Column
        field="attributes_count"
        :header="ts.tModule('datamodel_info_header.attribute_count')"
        sortable
      />
      <Column>
        <template #body="slotProps">
          <div class="flex justify-content-end">
            <Button
              icon="mdi mdi-dots-vertical"
              text
              plain
              aria-haspopup="true"
              aria-controls="overlay_menu"
              @click="(event: MouseEvent) =>toggleActionMenu(event, slotProps.data)"
            ></Button>
            <Menu id="overlay_menu" ref="menu" class="w-auto" :model="menuItems()" :popup="true" />
          </div>
        </template>
      </Column>
      <template #empty>
        <EmptyState
          :translation-service="ts"
          :icon-name="'datamodels'"
          :button-icon="'mdi mdi-plus'"
          @clicked="showAddDialog = true"
        />
      </template>
    </DataTable>
    <!-- #endregion -->

    <!-- #region datamodel Dialogs -->
    <NameDatamodel
      v-if="showNameDialog"
      @created-datamodel="openAttributeDialog"
      @close="showNameDialog = false"
    />

    <RenameDatamodel
      v-if="showRenameDialog"
      :datamodel="selectedDatamodel!"
      @reload="loadAsyncData"
      @close="showRenameDialog = false"
    />

    <DatamodelCreate
      v-if="showAddDialog"
      :display="showAddDialog"
      :datamodel="datamodel"
      @close="showAddDialog = false"
      @refresh="loadAsyncData"
    />
    <DatamodelEdit
      v-if="showEditDialog && selectedDatamodel"
      :display="showEditDialog"
      :datamodel="datamodel"
      :selected-datamodel="selectedDatamodel"
      @close="showEditDialog = false"
      @refresh="loadAsyncData"
    />
    <!-- #endregion -->
    <ConfirmDialog group="datamodels" />
  </section>
</template>
