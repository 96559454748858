const translationObject = {
  title: 'Settings',
  description:
    'This is the workspace you are currently using, you can change some of the information on this screen.',
  generalContact: 'General contact',
  financialContact: 'Financial contact',
  logo: 'Workspace logo',
  name: 'Workspace name',
  save: 'Save changes',
} as const;

export default translationObject;
