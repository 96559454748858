import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';

const create: TranslationObjectAction = {
  title: 'Create Brand',
  subTitle: 'Here you can create a new Brand.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete Brand',
};

const update: TranslationObjectAction = {
  title: 'Edit Brand',
  subTitle: 'Here you can edit a Brand.',
};

const translationObject = {
  title: 'Search',

  create: create,
  delete: deleteTranslation,
  update: update,

  advanced_search_placeholder: 'Start typing to search or select a saved search',
  simple_search_placeholder: 'Start typing to search',
  confirm_save: 'Are you sure you want to save this search?',
  save_search_name: 'Enter name for this search...',
} as const;

export default translationObject;
