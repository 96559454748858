const translationObject = {
  title: 'Search',
  placeholder: '',

  advancedSearchPlaceholder: 'Start typing to search or select a saved search',
  confirmSave: 'Are you sure you want to save this search?',

  advanced_search: {
    only_show_selected_products: 'Only show the seleted products',
  },
} as const;

export default translationObject;
