import ActivitiesTranslation from 'retailer/modules/activities/translations/en';
import AttributeTranslations from 'retailer/modules/attributes/translation/en';
import BrandsTranslations from 'retailer/modules/brands/translation/en';
import CategoryTranslations from 'retailer/modules/categories/translation/en';
import ClientsTranslations from 'retailer/modules/clients/translation/en';
import CommentTranslations from 'retailer/modules/comments/translation/en';
import DashboardTranslations from 'retailer/modules/dashboard/translation/en';
import DatamodelTranslations from 'retailer/modules/datamodels/translation/en';
import DevelopersTranslations from 'retailer/modules/developers/translations/en';
import DownloadTranslations from 'retailer/modules/downloads/translation/en';
import EditorTranslations from 'retailer/modules/editor/translation/en';
import ExportMappingsTranslations from 'retailer/modules/export-mappings/translation/en';
import ExportTranslations from 'retailer/modules/export/translation/en';
import ImportMappingItemsTranslations from 'retailer/modules/import-mapping-items/translation/en';
import ImportMappingsTranslations from 'retailer/modules/import-mappings/translation/en';
import ImportTranslations from 'retailer/modules/import/translation/en';
import ScheduledImport from 'retailer/modules/import/translation/import-scheduled.en';
import IncompleteProductTranslations from 'retailer/modules/incomplete-products/translation/en';
import LocaleTranslations from 'retailer/modules/locales/translation/en';
import MediaTranslations from 'retailer/modules/media/translation/en';
import ModuleTranslations from 'retailer/modules/modules/translation/en';
import ProductTranslations from 'retailer/modules/products/translation/en';
import SearchTranslations from 'retailer/modules/search/translation/en';
import SettingsTranslation from 'retailer/modules/settings/translation/en';
import TasksTranslations from 'retailer/modules/tasks/translation/en';
import UsersTranslations from 'retailer/modules/users/translation/en';
import WorkspaceSettingsTranslations from 'retailer/modules/workspace-settings/translations/en';

export default {
  retailer: {
    brands: BrandsTranslations,
    attributes: AttributeTranslations,
    dashboard: DashboardTranslations,
    datamodels: DatamodelTranslations,
    editor: EditorTranslations,
    locales: LocaleTranslations,
    categories: CategoryTranslations,
    products: ProductTranslations,
    comments: CommentTranslations,
    downloads: DownloadTranslations,
    incompleteProducts: IncompleteProductTranslations,
    modules: ModuleTranslations,
    search: SearchTranslations,
    exports: ExportTranslations,
    tasks: TasksTranslations,
    users: UsersTranslations,
    clients: ClientsTranslations,
    activities: ActivitiesTranslation,
    settings: SettingsTranslation,
    media: MediaTranslations,
    export_mappings: ExportMappingsTranslations,
    importMappings: ImportMappingsTranslations,
    imports: ImportTranslations,
    scheduledImport: ScheduledImport,
    importMappingItems: ImportMappingItemsTranslations,
    developers: DevelopersTranslations,
    'workspace-settings': WorkspaceSettingsTranslations,
  },
};

export type RetailerTranslationsType = typeof import('./en').default.retailer;
