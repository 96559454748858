<script setup lang="ts">
import { useService } from '@/general/composables/useService';
import { ImportService } from '@/general/services/overview-service/modules/import.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import EmptyState from '@/general/ui/components/empty-state.vue';
import SvgIcon from '@/general/ui/components/svg-icon.vue';
import { FileUploadSelectEvent, FileUploadUploaderEvent } from 'primevue/fileupload';
import { importServiceKey } from '../../ts/keys/import-service.key';

const service = useService(importServiceKey, ImportService);
const ts = new TranslationService('supplier', 'imports');
</script>

<template>
  <section class="m-3">
    <div class="border-1 border-gray-200">
      <div class="border-bottom-1 border-gray-200">
        <FileUpload
          mode="basic"
          multiple
          custom-upload
          auto
          :pt="{
            root: {
              class: 'justify-content-start bg-gray-50 p-2',
            },
          }"
          :choose-icon="'pi pi-upload'"
          :choose-label="ts.tGlobal('upload')"
          :disabled="service.uploading"
          :accept="(service.partialObject.module?.allowed_file_types ?? []).join(',')"
          @uploader="(event: FileUploadUploaderEvent) => service.uploadFiles(event)"
          @select="(event: FileUploadSelectEvent) => service.disableUploadPageButtonCondition(event.files)"
        />
      </div>
      <div
        v-if="service.partialObject.files?.length"
        class="overflow-y-auto p-2"
        style="max-height: 160px"
      >
        <div
          v-for="(file, index) in service.uploadedFilesResponse"
          :key="index"
          class="file-block m-2"
        >
          <SvgIcon :filename="file.public_url" custom-style="width: 4rem" />
          <div class="flex gap-1 justify-content-between mx-4 w-full">
            <span>{{ file.filename }}</span>
            <i class="pi pi-check text-green-400" />
          </div>
        </div>
      </div>
      <EmptyState
        v-else
        :translation-service="ts"
        :empty-state-title="ts.tModule('dialog.emptyStateTitle')"
        :empty-state-subtitle="ts.tModule('dialog.emptyStateSubtitle')"
        :icon-name="'media'"
        :button-visible="false"
      ></EmptyState>
    </div>
  </section>
</template>

<style scoped lang="scss">
.file-block {
  display: flex;
  height: 70px;
  padding: 6px 12px 6px 8px;
  align-items: center;
  align-self: stretch;
  border-radius: 3px;
  border: 1px solid #f5f7fa;
  background: #fff;
}

.image-container {
  width: 4rem;
  height: 4rem;
}

.image-block {
  object-fit: contain;
  height: 100%;
}
</style>
