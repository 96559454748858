<script setup lang="ts">
//components
import { SingleImageSpecsDetailClass } from '@/api/v1/content-story/ts/classes/single-image-specs-detail.class';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Upload } from 'platform-unit2-api/uploads';
import { inject } from 'vue';
import { storyBuilderServiceKey } from '../../../ts/helpers/story-manager-keys';
import BaseContentComponent from '../content-components/base-content-component.vue';
import ContentBulletPoints from '../content-components/content-bullet-points.vue';
import ContentHeadline from '../content-components/content-headline.vue';
import ContentImage from '../content-components/content-image.vue';
import ContentTextarea from '../content-components/content-textarea.vue';
interface Props {
  uuid: string;
}

const props = defineProps<Props>();

// inject / provide
const storyService = inject(storyBuilderServiceKey);
const ts = new TranslationService('supplier', 'storyManager');
// const / refs
const storyModule = storyService?.value.getModule<SingleImageSpecsDetailClass>(props.uuid);
</script>

<template>
  <BaseContentComponent :uuid="uuid">
    <template #content>
      <div class="flex flex-column h-full">
        <ContentHeadline
          :custom-place-holder="ts.tModule('storyBuilder.customHeadline.mainheadline')"
          class="header"
          :headline="storyModule?.getHeadline('headline')"
          @headline-value="storyModule?.setHeadline('headline', $event)"
        />
        <div class="flex gap-3 main">
          <div class="w-4" style="height: 50%; aspect-ratio: 1/1">
            <ContentImage
              :is-upload-set="storyModule?.hasUploadSet()"
              :upload-url="storyModule?.getUploadUrl()"
              :minimal-image-size="storyModule?.minimumImageSize"
              :accepted-file-types="storyModule?.acceptedFileTypes"
              @uploaded-image="(event: Upload) => storyModule?.setUploadData(event)"
            />
            <ContentHeadline
              :custom-place-holder="ts.tModule('storyBuilder.customHeadline.imageKeyWords')"
              :custom-class="'mt-3'"
              :headline="storyModule?.getAltText()"
              @headline-value="storyModule?.setAltText($event)"
            />
          </div>
          <div class="flex flex-column w-4">
            <ContentHeadline
              custom-class="mb-3"
              :headline="storyModule?.getHeadline('descriptionHeadline')"
              @headline-value="storyModule?.setHeadline('descriptionHeadline', $event)"
            />
            <ContentHeadline
              custom-class="mb-3"
              :custom-place-holder="ts.tModule('storyBuilder.customHeadline.subheadline')"
              :headline="storyModule?.getStandardTextBlockHeadline('descriptionBlock1')"
              @headline-value="
                storyModule?.setStandardTextBlockHeadline('descriptionBlock1', $event)
              "
            />
            <ContentTextarea
              :textbox="storyModule?.getStandardTextBlockText('descriptionBlock1')"
              @html-text-area="storyModule?.setStandardTextBlockText('descriptionBlock1', $event)"
            />
            <ContentHeadline
              :headline="storyModule?.getStandardTextBlockHeadline('descriptionBlock2')"
              @headline-value="
                storyModule?.setStandardTextBlockHeadline('descriptionBlock2', $event)
              "
            />
            <ContentTextarea
              :textbox="storyModule?.getStandardTextBlockText('descriptionBlock2')"
              @html-text-area="storyModule?.setStandardTextBlockText('descriptionBlock2', $event)"
            />
          </div>
          <div class="align-items-end flex flex-column w-4">
            <div class="flex flex-column h-full w-11">
              <ContentHeadline
                custom-class="mb-3"
                :headline="storyModule?.getHeadline('specificationHeadline')"
                @headline-value="storyModule?.setHeadline('specificationHeadline', $event)"
              />
              <ContentHeadline
                custom-class="mb-3"
                :custom-place-holder="ts.tModule('storyBuilder.customHeadline.subheadline')"
                :headline="storyModule?.getBulletPointHeadline()"
                @headline-value="storyModule?.setBulletPointHeadline($event)"
              />
              <ContentBulletPoints :uuid="uuid" />
              <ContentHeadline
                custom-class="mt-5 mb-3"
                :headline="storyModule?.getStandardTextBlockHeadline('specificationTextBlock')"
                @headline-value="
                  storyModule?.setStandardTextBlockHeadline('specificationTextBlock', $event)
                "
              />
              <ContentTextarea
                :textbox="storyModule?.getStandardTextBlockText('specificationTextBlock')"
                @html-text-area="
                  storyModule?.setStandardTextBlockText('specificationTextBlock', $event)
                "
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseContentComponent>
</template>

<style lang="scss" scoped>
.header {
  height: 5%;
}

.main {
  height: 95%;
}
</style>
