import useStorage from 'utils/storage';
import { User, UserRestService } from 'platform-unit2-api/users';
import { computed } from 'vue';
import store from '@/core/store';

export class AdminModeToggleService {
  private _urlMap = new Map<string, string>([
    ['localhost', 'http://localhost:8088'],
    ['dev.worldofcontent.com', 'https://dev-retail-admin.productsup.dev'],
    ['staging.worldofcontent.com', 'https://staging-retail-admin.productsup.dev'],
    ['retail.productsup.com', 'https://retail-admin.productsup.com/'],
  ]);
  private _userService = new UserRestService();

  isHostCorrect(adminToggled: boolean): boolean {
    const host = window.location.hostname;
    return adminToggled ? !this._urlMap.has(host) : this._urlMap.has(host); //! if the key exists, the host is NOT an admin
  }

  public async toggleAdminMode(status: boolean): Promise<void> {
    const currentUser = computed<User>(() => store.getters['users/currentUser']);

    await this._userService.toggleAdminMode(currentUser.value.id, status);
    this.switchToAdminPanel(status);
  }

  private _storeWorkspaceAdminStatus(status: boolean): void {
    const storage = useStorage();
    const workspace = storage.getStorageItem('workspace');
    if (workspace) {
      workspace.admin = status;
      storage.setStorageItem('workspace', workspace);
    }
  }

  public switchToAdminPanel(status: boolean): void {
    if (!status) {
      this.toggleAdminMode(false);
      return;
    }

    const url = this._urlMap.get(window.location.hostname);

    if (!url) return;

    this._storeWorkspaceAdminStatus(status);

    window.location.href = url;
  }
}
