import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';

const create: TranslationObjectAction = {
  title: 'Create Attribute',
  subTitle: 'Here you can create a new Attribute.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete Attribute',
};

const update: TranslationObjectAction = {
  title: 'Edit Attribute',
  subTitle: 'Here you can edit an Attribute.',
};

const translationObject = {
  title: 'Attribute | Attributes',
  placeholder: 'Select Attribute | Select Attributes',

  create: create,
  delete: deleteTranslation,
  update: update,
} as const;

export default translationObject;
