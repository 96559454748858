<script setup lang="ts">
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';
import { TranslationService } from '@/general/services/translations/translation.service';
import EmptyState from '@/general/ui/components/empty-state.vue';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { inject } from 'vue';
import { ProductAttributeViewService } from '../../services/product-attribute-view.service';

/** Props */
export interface Props {
  selectedDatamodel?: Datamodel;
}

defineProps<Props>();

/** Services */
const ts = new TranslationService('supplier', 'products');
const productAttributeFieldService = inject(productAttributeFieldsServiceKey)!;
const productAttributeViewService = new ProductAttributeViewService();
</script>

<template>
  <!-- When there is no export mapping for the channel -->
  <EmptyState
    v-if="
      selectedDatamodel &&
      selectedDatamodel.attributes?.length === 0 &&
      productAttributeFieldService.searchQuery == null &&
      !productAttributeFieldService.loading &&
      productAttributeFieldService.currentProduct?.module_id != null
    "
    :translation-service="ts"
    :icon-name="'channels'"
    :empty-state-title="ts.tModule('product_attributes.no_export_mappings_title')"
    :empty-state-subtitle="ts.tModule('product_attributes.no_export_mappings_subtitle')"
    :button-label="ts.tGlobal('contactSales')"
    :button-icon="'mdi mdi-send'"
    @clicked="productAttributeViewService.contactSales()"
  />

  <!-- When there are no attributes attached to the datamodel -->
  <EmptyState
    v-else-if="
      selectedDatamodel?.attributes?.length === 0 &&
      productAttributeFieldService.searchQuery == null &&
      !productAttributeFieldService.loading &&
      productAttributeFieldService.currentProduct?.module_id == null
    "
    :translation-service="ts"
    :icon-name="'master-data'"
    :empty-state-title="ts.tModule('product_attributes.no_attributes_attached_title')"
    :empty-state-subtitle="ts.tModule('product_attributes.no_attributes_attached')"
    :button-visible="false"
  />

  <!-- When the empty fields mode returns no fields to fill in -->
  <EmptyState
    v-else-if="
      productAttributeFieldService.showOnlyEmptyFields &&
      !productAttributeFieldService.showFieldsWithError &&
      !productAttributeFieldService.showFilledInFields &&
      !productAttributeFieldService.hasEmptyFields &&
      !productAttributeFieldService.loading
    "
    :empty-state-subtitle="''"
    :translation-service="ts"
    :icon-name="'master-data'"
    :empty-state-title="ts.tModule('product_attributes.no_empty_fields')"
    :button-visible="false"
  />

  <!-- When the show error fields mode returns no fields to fill in -->
  <EmptyState
    v-else-if="
      !productAttributeFieldService.showOnlyEmptyFields &&
      productAttributeFieldService.showFieldsWithError &&
      !productAttributeFieldService.showFilledInFields &&
      !productAttributeFieldService.hasEmptyFields &&
      !productAttributeFieldService.loading
    "
    :empty-state-subtitle="''"
    :translation-service="ts"
    :icon-name="'master-data'"
    :empty-state-title="ts.tModule('product_attributes.no_fields_with_error')"
    :button-visible="false"
  />

  <!-- When the show filled in fields mode returns no fields to fill in -->
  <EmptyState
    v-else-if="
      !productAttributeFieldService.showOnlyEmptyFields &&
      !productAttributeFieldService.showFieldsWithError &&
      productAttributeFieldService.showFilledInFields &&
      !productAttributeFieldService.hasEmptyFields &&
      !productAttributeFieldService.loading
    "
    :empty-state-subtitle="''"
    :translation-service="ts"
    :icon-name="'master-data'"
    :empty-state-title="ts.tModule('product_attributes.no_filled_in_fields')"
    :button-visible="false"
  />

  <!-- When there are are attributes attached to the datamodel and the user is searching -->
  <EmptyState
    v-else-if="
      productAttributeFieldService.attributeFields.length === 0 &&
      !productAttributeFieldService.loading
    "
    :empty-state-subtitle="''"
    :translation-service="ts"
    :icon-name="'master-data'"
    :empty-state-title="
      ts.tModule(
        productAttributeFieldService.searchQuery != null
          ? 'no_fields_found'
          : 'product_attributes.no_attributes_attached_title',
      )
    "
    :button-visible="false"
  />
</template>
