<script setup lang="ts">
import CrudButtons from '@/general/ui/components/crud-buttons/crud-buttons.vue';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { DialogProps } from 'primevue/dialog';

export type DialogSize = 'small' | 'medium' | 'large';

/** Props */
export interface Props extends DialogProps {
  visible: boolean;
  buttonsOptions?: CrudButtonsOptions;
  title?: string;
  subtitle?: string;
  closable?: boolean;
  showFooter?: boolean;
  size?: DialogSize;
}

withDefaults(defineProps<Props>(), {
  closable: true,
  buttonsOptions: undefined,
  title: undefined,
  subtitle: undefined,
  showFooter: true,
  size: 'medium',
});
/** Emits */
const emit = defineEmits<{
  (e: 'update:visible', value: boolean): void;
  (e: 'submit'): void;
}>();
</script>

<template>
  <Dialog
    :visible="visible"
    modal
    :closable="closable"
    :close-on-escape="false"
    position="center"
    :draggable="false"
    v-bind="$attrs"
    :style="style"
    :class="'dialog-size-' + size"
    :content-style="contentStyle"
    :content-class="contentClass"
    @update:visible="(value: boolean) => emit('update:visible', value)"
  >
    <!-- #region: Header -->
    <template #header>
      <slot name="header">
        <div class="pt-2">
          <h3 class="font-normal mb-2">{{ title }}</h3>
          <p>{{ subtitle }}</p>
        </div>
      </slot>
    </template>
    <!--  #endregion -->

    <!--  #region: Content -->
    <form name="base-dialog-form" class="h-full w-full" @submit.prevent="emit('submit')">
      <slot></slot>
    </form>
    <!--  #endregion -->

    <!--  #region: Footer -->
    <template v-if="showFooter" #footer>
      <form name="base-dialog-form" class="w-full" @submit.prevent="emit('submit')">
        <div class="align-items-start bg-white flex">
          <slot name="footer">
            <CrudButtons v-if="buttonsOptions" :options="buttonsOptions" />
          </slot>
        </div>
      </form>
    </template>
    <!-- #endregion -->
  </Dialog>
</template>
