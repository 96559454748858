<script setup lang="ts">
import { HierarchyPosition, ProductsRestService } from 'platform-unit2-api';
import BaseSelect from './base-select.vue';
import { DefaultSelectProps } from './ts/default-select-props.interface';

const props = defineProps<DefaultSelectProps<HierarchyPosition>>();
const productsRestService: any = ProductsRestService;
</script>
<template>
  <BaseSelect
    :rest-service="productsRestService"
    fetch-function-name="getHierarchyPositions"
    option-label="name"
    data-key="value"
    display="chip"
    module-name="hierarchyPosition"
    class="w-full"
    v-bind="{ ...$attrs, ...props }"
  />
</template>
