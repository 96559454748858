import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';

const create: TranslationObjectAction = {
  title: 'Create Export Mapping',
  subTitle: 'Here you can create a new Export Mapping.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete Export Mapping',
};

const update: TranslationObjectAction = {
  title: 'Edit Export Mapping',
  subTitle: 'Here you can edit a Export Mapping.',
};

const translationObject = {
  title: 'Export Mapping | Export Mappings',
  placeholder: 'Select Export Mapping | Select Export Mappings',
  emptyStateSubtitle:
    'Start by selecting a datamodel and channel to create your first export\nmapping. When an export mapping is created it will show here.',

  create: create,
  delete: deleteTranslation,
  update: update,

  tableHeaders: {
    attributesMapped: 'Attributes mapped',
    lastUpdated: 'Last updated',
  },

  mapping: 'Mapping',
  mappingType: 'Mapping type',
  supported: 'Supported',
  unsupported: 'Unsupported',
  compare_warning:
    'There are not enough mappings to compare. Please duplicate the mappings and try again.',
  sync_warning:
    'There are not enough mappings to sync. Please duplicate the mappings and try again.',
  duplicate_warning:
    'There are not enough mappings to duplicate. Please create some mappings to duplicate.',
  field_sources: {
    take_value: 'Take value',
    take_key: 'Take key',
    take_number: 'Take number',
    take_unit: 'Take unit',
    take_text: 'Take text',
    take_html: 'Take HTML',
    take_list: 'Take list',
    take_list_item: 'Take list item:',
    take_locale: 'Take locale',
  },
  createTitle: 'Create Export Mapping',
  export_mapping_supported: {
    mapAttributes: 'Map the attributes',
    mapAttributesSubtitles: 'Map the attributes from your data model to the excel attributes.',
    select_source: 'Select a source',
    attribute_exists: 'Attribute already exists in field',
    adding_failed: 'Adding attributes failed',
    original: 'original',
    no_attributes_title: 'No attributes found',
    no_attributes_subtitle:
      'This data model has no attributes. Please add some attributes to continue.',
    no_template_endpoints_title: 'No template endpoints found',
    no_template_endpoints_subtitle:
      'This channel has no template endpoints. Please contact support team or create an unsupported mapping',
  },
  export_mapping_unsupported: {
    source: 'Source',
    target: 'Target',
    placeholder: 'Select type',
    add_records: 'Add new records',
    copy_to_target: 'Click to copy to `target`',
  },
  sync_mappings_modal: {
    title: 'Sync mappings',
    info: 'Select the workspace/client to use as base for Syncing. Most of the time you would select Master Mappings. This are the mappings provided/and maintained by Productsup. Second, select the set of mappings to sync with the previously selected mappings.',
    warning: ' Note, this is only syncs the supported mappings.',
    sync: 'Sync',
    sync_successful: 'Synced mappings!',
    attach_failed: 'We were unable attach products to the task. Please try again',
  },
  duplicate_mappings: {
    title: 'Duplicate mappings',
    modal_header: 'Duplicate export mappings',
    modal_description:
      'Choose the destination for the export mapping duplicate by selecting the appropriate workspace, data model and channel.',
    modal_client_placeholder: 'Select workspace',
    duplication_fail: 'Duplicating mappings failed. Please try again.',
    duplication_success: 'Mapping has been successfully duplicated',
    duplication_overwrite_success: 'Mapping has been successfully overwritten',
    duplication_warning:
      'Some attributes do not match in the destination of the duplicated mapping',
    duplication_error_same_destination:
      'The destination of the duplicate mapping is the same as the original',
    overwrite_title: 'Overwrite export mapping',
    overwrite_description:
      'An export mapping already exists for the selected destination. By choosing ‘overwrite’ this export mapping will be replaced with the duplicate. This can not be undone.',
    overwrite: 'Overwrite',
    info_message:
      'This action will duplicate both supported and unsupported mappings if they exist.',
  },
  datamodel_attributes: 'Data model attributes',
  export_template_attributes: 'Export template attributes',
} as const;

export default translationObject;
