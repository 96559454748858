import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';

const create: TranslationObjectAction = {
  title: 'Create Scheduled import',
};

const translationObject = {
  title: 'Scheduled import | Scheduled imports',

  create: create,
} as const;

export default translationObject;
