import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';

const create: TranslationObjectAction = {
  title: 'Create workspace',
  subTitle: 'Here you can create a new workspace.',
};

const update: TranslationObjectAction = {
  title: 'Edit workspace',
  subTitle: 'Here you can edit a workspace.',
};

const translationObject = {
  title: 'Workspace | Workspaces',
  placeholder: 'Select a workspace | Select workspaces',

  general_contact: 'General contact',
  financial_contact: 'Financial contact',

  create: create,
  update: update,
} as const;

export default translationObject;
