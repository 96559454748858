<script setup lang="ts">
//vue
import { ref } from 'vue';

//components
import EmptyStateUploadOrMediaPicker from '@/general/ui/components/empty-state-upload-or-media-picker/empty-state-upload-or-media-picker.vue';
import Upload from 'ui/components/upload.vue';

//types
import { Upload as UploadFile } from 'platform-unit2-api/uploads';

//services
import { TranslationService } from '@/general/services/translations/translation.service';

/** Props */
export interface Props {
  acceptedFileTypes?: string;
  minimalImageSize?: `${string} : ${string}`;
  isUploadSet: boolean | undefined;
  uploadUrl: string | undefined;
  customContainerClass?: string;
  customLibraryButtonLabel?: string;
}

withDefaults(defineProps<Props>(), {
  isFocused: false,
  minimalImageSize: undefined,
  acceptedFileTypes: '*',
  customContainerClass: 'h-full',
  customLibraryButtonLabel: undefined,
});

/** Emits */
const emit = defineEmits<{ (e: 'uploaded-image', uploadedImage: UploadFile): void }>();

/** Services */
const ts = new TranslationService('supplier', 'storyManager');

/** Const / ref */
const uploadedFile = ref<UploadFile>();
const openMediaPicker = ref<boolean>(false);
const isImageFocused = ref<boolean>(false);

const handleReupload = () => {
  openMediaPicker.value = true;
};

const handleUpload = (event?: UploadFile) => {
  if (event == null) {
    return;
  }

  uploadedFile.value = event;
  emit('uploaded-image', event);
};
</script>

<template>
  <div :class="[!isUploadSet ? customContainerClass : 'hidden']">
    <EmptyStateUploadOrMediaPicker
      :show-title="false"
      :is-able-to-pick-multiple-images="false"
      :accepted-file-types="acceptedFileTypes"
      :minimal-image-size="minimalImageSize"
      :open-media-picker="openMediaPicker"
      :custom-library-button-label="customLibraryButtonLabel"
      @chosen-file="(event?: UploadFile) => handleUpload(event)"
      @reset-media-picker-state="(event: boolean) => openMediaPicker = event"
    />
  </div>
  <div
    :class="isUploadSet ? `${customContainerClass} relative w-full` : ''"
    tabindex="2"
    @focus="isImageFocused = true"
    @focusout="isImageFocused = false"
  >
    <Upload
      v-if="isUploadSet"
      additional-image-style="object-fit: contain"
      :upload="({public_thumbnail_url : uploadUrl} as UploadFile)"
      :additional-container-style="'height: 100%;'"
      :additional-image-class="'w-full h-full bg-contain bg-center'"
    />
    <div
      :class="
        isImageFocused && isUploadSet
          ? 'block w-full h-full flex justify-content-center align-items-center absolute top-0 left-0 focus-state'
          : 'hidden'
      "
    >
      <Button
        :label="ts.tModule('storyBuilder.storyStatusBar.changeAsset')"
        class="h-4rem p-button-outlined text-white"
        @mousedown="$event.preventDefault(), handleReupload()"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.focus-state {
  border-radius: 0.27rem;
  background: linear-gradient(0deg, rgba(23, 37, 51, 0.5), rgba(23, 37, 51, 0.5));
}
.h85 {
  height: 85%;
}
</style>
