import i18n from '@/core/i18n';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Composer } from 'vue-i18n';

const ts = new TranslationService(
  'supplier',
  'products',
  process.env.NODE_ENV !== 'production',
  i18n.global as unknown as Composer,
);

export interface TableColumnInterface {
  selected: boolean;
  field: string;
  header: string;
  sortable: boolean;
  filter: boolean;
  type: string;
  size: string;
}
export const productTableColumns: TableColumnInterface[] = [
  {
    selected: true,
    field: 'gtin',
    header: ts.t('global.gtin'),
    sortable: true,
    filter: false,
    type: 'gtin',
    size: '12rem',
  },
  {
    selected: true,
    field: 'display_name',
    header: ts.t('supplier.products.product_name'),
    sortable: true,
    filter: false,
    type: 'display_name',
    size: '24rem',
  },
  {
    selected: false,
    field: 'images_included',
    header: ts.t('supplier.products.assetsIncluded'),
    sortable: false,
    filter: false,
    type: 'images_included',
    size: '17rem',
  },
  {
    selected: true,
    field: 'brand_id',
    header: ts.t('supplier.brands.title'),
    sortable: true,
    filter: false,
    type: 'brand',
    size: '14rem',
  },
  {
    selected: true,
    field: 'variant_name',
    header: ts.t('supplier.products.variant_name'),
    sortable: true,
    filter: true,
    type: 'variant',
    size: '16rem',
  },

  {
    selected: true,
    field: 'hierarchy_position',
    header: ts.t('supplier.products.hierarchy_position'),
    sortable: true,
    filter: true,
    type: 'hierarchy_position',
    size: '16rem',
  },
  {
    selected: true,
    field: 'category',
    header: ts.t('supplier.categories.title'),
    sortable: true,
    filter: false,
    type: 'category',
    size: '14rem',
  },

  {
    selected: true,
    field: 'completeness',
    header: ts.t('global.completeness_score'),
    sortable: true,
    filter: false,
    type: 'completeness',
    size: '16rem',
  },
  {
    selected: true,
    field: 'datamodel_id',
    header: ts.t('supplier.datamodels.title'),
    sortable: true,
    filter: false,
    type: 'datamodel',
    size: '16rem',
  },

  {
    selected: true,
    field: 'shared_retailer',
    header: ts.t('supplier.products.shared_retailer'),
    sortable: false,
    filter: false,
    type: 'shared_retailer',
    size: '8rem',
  },
  {
    selected: true,
    field: 'module_id',
    header: ts.t('supplier.modules.title'),
    sortable: true,
    filter: false,
    type: 'module',
    size: '12rem',
  },
  {
    selected: true,
    field: 'product_status_id',
    header: ts.t('global.status'),
    sortable: true,
    filter: false,
    type: 'status',
    size: '14rem',
  },

  {
    selected: true,
    field: 'updated_at',
    header: ts.t('global.updated_at'),
    sortable: true,
    filter: false,
    type: 'updated_at',
    size: '16rem',
  },
  {
    selected: true,
    field: 'created_at',
    header: ts.t('global.created_at'),
    sortable: true,
    filter: true,
    type: 'created_at',
    size: '16rem',
  },
];
