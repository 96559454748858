import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';

const create: TranslationObjectAction = {
  title: 'Create brand',
  subTitle: 'Here you can create a new brand.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete brand',
};

const update: TranslationObjectAction = {
  title: 'Edit brand',
  subTitle: 'Here you can edit a brand.',
};

const translationObject = {
  title: 'Brand | Brands',
  placeholder: 'Select a brand | Select brands',

  create: create,
  delete: deleteTranslation,
  update: update,
} as const;

export default translationObject;
