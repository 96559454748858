const translationObject = {
  title: 'Dashboard',
  placeholder: 'Select Brand | Select Brands',

  refreshLimit: 'Dashboard can only be refreshed once every hour',
  refreshButtonToggle: 'Dashboard can only be refreshed once every two minutes',
  calendarTitle: 'Calendar',
  completeness: 'Completeness',
  userDashboard: {
    selectToFilter: 'Select the options',
    selectAll: 'Select all',
    metrics: {
      inTotal: 'In total',
      metricData: 'metric data',
      in_total: 'In total',
      metric_data: 'metric data',
      products_metric_title: 'Created products',
      products_metric_title_retailer: 'Available products',
      product_fields_metric_title: 'Enriched fields',
      product_fields_metric_title_retailer: 'Enriched fields',
      assets_metric_title: 'Imported media',
      assets_metric_title_retailer: 'Imported media',
      refresh_data: 'Refresh data',
      refreshData: 'Refresh data',
    },
    activity: {
      title: 'Completeness score over time',
      info: 'Scroll on chart to zoom-in',
      completenessScore: 'Completeness score',
      masterData: 'Master Data',
      filters: 'Filters',
    },
    overall_completeness: {
      title: 'Overall completeness',
    },
    organisation_labels: {
      title: 'Organisation labels',
    },
    moduleCompleteness: {
      title: 'Channel analytics',
    },
    brand_completeness: {
      title: 'Master data analytics',
      no_brands: 'No brands found',
    },
    updated_products: {
      title: 'Recently updated products',
    },
    calendar: {
      info: 'This view contains your calendar. In this calendar we populate when products have been added, changed or removed. Clicking on these entries will take you to the product page and show the list of changed products. Secondly tasks are displayed on this calendar. Again clicking the task will show you more details about this task.',
      scheduled: '{name} {type} will run',
      productsAdded: '{count} added',
      productsEdited: '{count} edited',
      productsDeleted: '{count} deleted',
    },
  },

  user_dashboard: {
    select_to_filter: 'Select the options',
    select_all: 'Select all',
    metrics: {
      in_total: 'In total',
      metric_data: 'metric data',
      products_metric_title: 'Created products',
      products_metric_title_retailer: 'Available products',
      product_fields_metric_title: 'Enriched fields',
      product_fields_metric_title_retailer: 'Enriched fields',
      assets_metric_title: 'Imported media',
      refresh_data: 'Refresh data',
    },
    activity: {
      title: 'Completeness score over time',
      info: 'Scroll on chart to zoom-in',
      completeness_score: 'Completeness score',
      master_data: 'Master Data',
      filters: 'Filters',
    },
    overall_completeness: {
      title: 'Overall completeness',
    },
    organisation_labels: {
      title: 'Organisation labels',
    },
    module_completeness: {
      title: 'Channel Analytics',
      empty: 'There are no items to display.',
    },
    brandCompleteness: {
      title: 'Master Data Analytics',
      noBrands: 'No brands found',
    },
    updated_products: {
      title: 'Recently Updated Products',
    },
  },
  calendar: {
    info: 'This view contains your calendar. In this calendar we populate when products have been added, changed or removed. Clicking on these entries will take you to the product page and show the list of changed products. Secondly tasks are displayed on this calendar. Again clicking the task will show you more details about this task.',
    scheduled: '{name} {type} will run',
    products_added: '{count} added',
    products_edited: '{count} edited',
    products_deleted: '{count} deleted',
  },
  emptyState: {
    products: {
      title: 'There are no products yet',
      subtitle:
        'Start by creating your first product. When a product is created it will show here.',
      button: 'Create product',
    },
    channels: {
      title: 'There are no channel analytics to display yet',
      subtitle:
        'Start by adding your first channel to receive channel analytics. Contact sales to inquire about available channels.',
    },
    tasks: {
      title: 'There are no tasks yet',
      subtitle:
        'Start by creating a task to streamline the workflow of your team inside the \nProductsup platform.When a task is created it will show here.',
      button: 'Create task',
    },
    brands: {
      title: 'There are no master data analytics to display yet',
      subtitle:
        'Start by creating your first product. When a product is created, the master data analytics will show here.',
      button: 'Create product',
    },
  },
} as const;

export default translationObject;
