const translationObject = {
  title: 'HTML Editor',

  copy_contents: 'Copy HTML to clipboard',
  insert: 'Insert',
  instructions:
    '<p>Use this editor to style your text and convert it to HTML.</p><p>This editor supports basic formatting for <strong>bold</strong>, <em>italic</em>, and <u>underlined text</u>.</p><p>Bullet lists are also supported, but try to avoid using regular bullets ("•"). Instead use the bullet point button in this editor, which generates a list like this:</p><ul><li>Item a</li><li>Item b</li><li>Item c</li></ul><p>When creating sections, simply start the section with one of these:</p><h2>Large Heading</h2><h3>Smaller Heading</h3><p>Finally, when done editing press the "Copy HTML to clipboard" button.</p>',
  insert_html: 'Insert HTML',
  insert_html_placeholder: '<p>Insert raw HTML</p>',
  insert_html_warning: 'Note: this will replace your current input.',
  succesfully_copied: 'Successfully copied HTML to clipboard!',
  back_to_application: 'Back to application',
  copiedToClipboard: 'Succesfully copied to clipboard',
  dialog: {
    title: 'Insert HTML',
    subtitle: 'Here you can easily insert your HTML code',
  },
} as const;

export default translationObject;
