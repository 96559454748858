import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';

const create: TranslationObjectAction = {
  title: 'Create category',
  subTitle: 'Here you can create a new category.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete category',
};

const update: TranslationObjectAction = {
  title: 'Edit category',
  subTitle: 'Here you can edit an category.',
};

const translationObject = {
  title: 'Category | Categories',
  placeholder: 'Select a category | Select categories',

  emptyStateSubtitle: 'No categories found, start by creating one',
  bulkAttachDescription: 'Here you can bulk attach your products to a category',
  bulkAttachSuccess: 'Successfully attached {count} products their respective categories',
  bulkAttachTitle: 'Bulk attach categories',
  bulkAttachNoVariants: 'No variants selected',
  parent: 'Parent',
  parentDescription:
    'This should be the category you want your category to be part of. Leave empty if you want it as a root category.',

  import: {
    title: 'Import Categories',
    subtitle: 'Here you can import categories from file',
  },

  detachAttributeSuccess: 'Successfully detached attribute from the category',
  detachAttributeError: 'There was a problem with detaching your attribute from the category',
  getAttributesError: 'There was a problem with getting the attributes for this category',
  noAttributes: 'No attributes',

  detach: 'Detach',

  create: create,
  delete: deleteTranslation,
  update: update,
} as const;

export default translationObject;
