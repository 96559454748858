import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';

const create: TranslationObjectAction = {
  title: 'Create template',
  subTitle: 'Here you can create a new template.',
};

const update: TranslationObjectAction = {
  title: 'Edit template',
  subTitle: 'Here you can edit a template.',
};

const translationObject = {
  title: 'Template | Templates',
  placeholder: 'Select a template | Select templates',

  new: {
    fileUploadNote: 'Upload an excel file to convert it to a template.',
    dataRowNote: 'Defines on which row the data starts',
    titleRowNote: 'Defines on which row the title starts',
    tableRow: 'Define the table rows',
    titleRow: 'The attributes <b>titles</b> are in row:',
    dataRow: 'The attributes <b>data</b> starts from row:',
    title: 'New template',
    subtitle: 'Here you can create a new template.',
    helpNote:
      'Upload an excel file that has the headers horizontally on the first row which can be used for filling with the product data when exporting',
  },

  edit: {
    title: 'Edit template',
    subtitle: 'Here you can edit a template.',
    inputColumn: 'Input column',
    change: 'Change file',
    upload: 'Upload file',
    endpoints: 'Endpoints',
    addEndpoint: 'Add new endpoints',
  },

  takeValue: 'Take value',
  takeList: 'Take list',
  takeListItem: 'Take list item',
  takeHtml: 'Take html',
  takeText: 'Take text',
  takeNumber: 'Take number',
  takeUnit: 'Take unit',

  unsupportedSource: 'Unsupported source',

  tableHeaders: {
    label: 'Label',
    key: 'Key',
  },

  create: create,
  update: update,
} as const;

export default translationObject;
