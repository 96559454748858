import AssetTypesTranslations from 'supplier/modules/asset-types/translation/en';
import AttributeTranslations from 'supplier/modules/attributes/translation/en';
import BrandsTranslations from 'supplier/modules/brands/translation/en';
import DashboardTranslations from 'supplier/modules/dashboard/translation/en';
import DatamodelTranslations from 'supplier/modules/datamodels/translation/en';
import EditorTranslations from 'supplier/modules/editor/translation/en';
import LocaleTranslations from 'supplier/modules/locales/translation/en';
import ModuleTranslations from 'supplier/modules/modules/translation/en';
import SearchTranslations from 'supplier/modules/search/translation/en';
import ProductTranslations from 'supplier/modules/products/translation/en';
import TasksTranslations from 'supplier/modules/tasks/translation/en';
import ThumbnailTranslations from 'supplier/modules/thumbnails/translation/en';
import UploadTranslations from 'supplier/modules/uploads/translation/en';
import CategoryTranslations from 'supplier/modules/categories/translation/en';
import UserTranslations from 'supplier/modules/users/translation/en';
import CommentTranslations from 'supplier/modules/comments/translation/en';
import DownloadTranslations from 'supplier/modules/downloads/translation/en';
import ExportTranslations from '@/platforms/supplier/modules/export/translation/en';
import ExportMappingTranslations from 'supplier/modules/export-mappings/translation/en';
import ImportTranslations from '@/platforms/supplier/modules/import/translations/en';
import ImportMappingTranslations from 'supplier/modules/import-mappings/translation/en';
import IncompleteProductTranslations from 'supplier/modules/incomplete-products/translation/en';
import MediaTranslations from '@/platforms/supplier/modules/media/ts/en';
import SharedTranslations from 'supplier/modules/shared/translation/en';
import ClientTranslations from 'supplier/modules/clients/translation/en';
import TemplatesTranslations from 'supplier/modules/templates/translation/en';
import SettingsTranslations from '@/platforms/supplier/modules/settings/translation/en';
import StoryManagerTranslations from 'supplier/modules/story-manager/translation/en';
import ImportMappingItems from 'supplier/modules/import-mapping-items/translation/en';
import ImportMappingSettingTranslation from '@/platforms/supplier/modules/import-mapping-setting/translation/en';
import ScheduledImport from 'supplier/modules/import/translations/import-scheduled.en';
import AITranslations from 'supplier/modules/ai-enhancements/translation/en';
import DatamodelConfigTranslations from 'supplier/modules/datamodel-attribute-configs/translations/en';
import JsonSchemasTranslations from 'supplier/modules/json-schemas/translations/en';
import DevelopersTranslations from 'supplier/modules/developers/translations/en';
import WorkspaceSettingsTranslations from 'supplier/modules/workspace-settings/translations/en';

export default {
  supplier: {
    assetTypes: AssetTypesTranslations,
    attributes: AttributeTranslations,
    dashboard: DashboardTranslations,
    brands: BrandsTranslations,
    shared: SharedTranslations,
    datamodels: DatamodelTranslations,
    editor: EditorTranslations,
    locales: LocaleTranslations,
    categories: CategoryTranslations,
    products: ProductTranslations,
    comments: CommentTranslations,
    downloads: DownloadTranslations,
    exports: ExportTranslations,
    export_mappings: ExportMappingTranslations,
    imports: ImportTranslations,
    importMappings: ImportMappingTranslations,
    incompleteProducts: IncompleteProductTranslations,
    modules: ModuleTranslations,
    media: MediaTranslations,
    search: SearchTranslations,
    tasks: TasksTranslations,
    thumbnails: ThumbnailTranslations,
    uploads: UploadTranslations,
    users: UserTranslations,
    settings: SettingsTranslations,
    clients: ClientTranslations,
    templates: TemplatesTranslations,
    storyManager: StoryManagerTranslations,
    scheduledImport: ScheduledImport,
    importMappingItems: ImportMappingItems,
    'ai-enhancements': AITranslations,
    datamodelsConfig: DatamodelConfigTranslations,
    jsonSchemas: JsonSchemasTranslations,
    ImportMappingSetting: ImportMappingSettingTranslation,
    developers: DevelopersTranslations,
    'workspace-settings': WorkspaceSettingsTranslations,
  } as const,
};

export type SupplierTranslationsType = typeof import('./en').default.supplier;
