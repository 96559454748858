const translationObject = {
  title: 'Download | Downloads',
  placeholder: '',

  downloadReady: 'Your files are ready!',
  senderMessage: '{name} from the {workspace} workspace has send you some files.',
  downloading: 'Downloading...',
  downloadAll: 'Download all {amount} files',
  helpText: 'Having trouble?  <span class="underline cursor-pointer">Send us a message</span>',
  noDownloadsTitle: 'Oops, looks like something went wrong!',
  noDownloadsSubtitle: 'Please check the Export or contact our support team',
  goToExports: 'Return to exports',
} as const;

export default translationObject;
