import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';

const create: TranslationObjectAction = {
  title: 'Create attribute',
  subTitle: 'Here you can create a new attribute.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete attribute',
};

const update: TranslationObjectAction = {
  title: 'Edit attribute',
  subTitle: 'Here you can edit an attribute.',
};

const translationObject = {
  title: 'Attribute | Attributes',
  placeholder: 'Select an attribute | Select attributes',

  create: create,
  delete: deleteTranslation,
  update: update,
} as const;

export default translationObject;
