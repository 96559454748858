import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';

const create: TranslationObjectAction = {
  title: 'Create a Enhancements',
  subTitle: 'Here you can create a new Enhancement',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete Enhancement',
};

const update: TranslationObjectAction = {
  title: 'Edit Enhancement',
  subTitle: 'Here you can edit a Enhancement.',
};

const translationObject = {
  title: 'AI Enhancement | AI Enhancements',

  create: create,
  delete: deleteTranslation,
  update: update,
} as const;

export default translationObject;
