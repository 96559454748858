import ComponentsTranslation from 'ui/components/en';
import ViewsTranslation from 'ui/views/en';

const translation = {
  general: {
    components: ComponentsTranslation,
    views: ViewsTranslation,
  } as const,
};
export type GeneralTranslationsType = typeof translation.general;
export default translation;
