import { GlobalTranslationsType } from '@/core/i18n/translations/en/global.translation';
import { Platform } from '@/core/router/route.factory';
import { GeneralTranslationsType } from '@/general/translations/en';
import { RetailerTranslationsType } from '@/platforms/retailer/translations/en';
import { SupplierTranslationsType } from '@/platforms/supplier/translations/en';
import { Composer, TranslateOptions, useI18n } from 'vue-i18n';
import { DebugLogger } from '@/core/main/debug.logger';

type PlatformSettingsType<P extends Platform> = P extends 'supplier'
  ? SupplierTranslationsType
  : P extends 'retailer'
  ? RetailerTranslationsType
  : P extends 'general'
  ? GeneralTranslationsType
  : GlobalTranslationsType;

type ModuleKeys<P extends Platform> = keyof PlatformSettingsType<P>;

type TranslationKeys<P extends Platform, M extends ModuleKeys<P>> = NestedKeyOf<
  PlatformSettingsType<P>[M]
>;

// Recursively generate dot-separated keys for a nested object
type NestedKeyOf<T> = T extends object
  ? {
      [K in keyof T]: K extends string
        ? T[K] extends object
          ? `${K}.${NestedKeyOf<T[K]>}` | K
          : K
        : never;
    }[keyof T]
  : never;

class BetterTranslationService<P extends Platform = never, M extends ModuleKeys<P> = never> {
  private currentPlatform?: P;
  private currentModule?: keyof PlatformSettingsType<P>;
  private _i18n: Composer;

  constructor(i18n = useI18n()) {
    this._i18n = i18n;
  }

  get key(): string {
    if (this.currentPlatform == null || this.currentModule == null) {
      DebugLogger.error(
        'Platform or module not set. Use the platform and module methods before calling the key getter.',
      );

      return '';
    }

    return `${this.currentPlatform}_${
      this.currentPlatform !== 'global' ? this.currentModule.toString() : ''
    }`;
  }

  platform<NewP extends Platform>(platform: NewP): BetterTranslationService<NewP> {
    this.currentPlatform = platform as unknown as P;
    return this as unknown as BetterTranslationService<NewP>;
  }

  module<NewM extends ModuleKeys<P>>(moduleName: NewM): BetterTranslationService<P, NewM> {
    if (this.currentPlatform == null) {
      DebugLogger.error(
        'Platform not set. In order to do that, use the platform method before the module one.',
      );

      return this;
    }

    this.currentModule = moduleName;
    return this as BetterTranslationService<P, NewM>;
  }

  translate<T extends TranslationKeys<P, M>>(
    key: T,
    count?: number,
    options?: TranslateOptions,
  ): string {
    if (this.currentPlatform == null || this.currentModule == null) {
      DebugLogger.error(
        'Platform or module not set. Use the platform and module methods before calling the translate method.',
      );

      return '';
    }

    const finalKey = `${this.currentPlatform}.${
      this.currentPlatform !== 'global' ? this.currentModule.toString() + '.' : ''
    }${key}`;

    return this._i18n.t(finalKey, count ?? 1, options);
  }
}

export default BetterTranslationService;
