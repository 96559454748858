<script setup lang="ts">
import { PaginationObject } from 'platform-unit2-api/core';
import { Module, ModulesRestService } from 'platform-unit2-api/modules';
import { ProductsRestService } from 'platform-unit2-api/products';
import BaseSelect from './base-select.vue';
import { DefaultSelectProps } from './ts/default-select-props.interface';

interface Props extends DefaultSelectProps<Module> {
  workspaceId?: number;
  onlyUsedByProducts?: boolean;
  paginationParams?: PaginationObject;
  customFetchParams?: any[];
}

const props = defineProps<Props>();
const restService: any = props.onlyUsedByProducts ? ProductsRestService : ModulesRestService;

function getCustomParams() {
  if (props.onlyUsedByProducts) {
    return [];
  }

  return [props.workspaceId];
}
</script>
<template>
  <BaseSelect
    :rest-service="restService"
    :fetch-function-name="onlyUsedByProducts ? 'searchProductModules' : 'getAll'"
    option-label="name"
    module-name="channel"
    class="w-full"
    v-bind="{ ...$attrs, ...props }"
    :custom-fetch-params="customFetchParams ?? getCustomParams()"
    :pagination-params="props.paginationParams"
  />
</template>
