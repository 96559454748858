import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';

const create: TranslationObjectAction = {
  title: 'Create Import',
  subTitle: 'Here you can create a new Import.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete Import',
};

const update: TranslationObjectAction = {
  title: 'Edit Import',
  subTitle: 'Here you can edit a Import.',
};

const translationObject = {
  title: 'Import | Imports',
  placeholder: 'Select Import | Select Imports',
  emptyStateSubtitle:
    'Start by creating your first import. Once your new import is saved it will show here.',

  create: create,
  delete: deleteTranslation,
  update: update,
  //
  ready_to_upload: 'Ready to upload',
  uploaded: 'Uploaded',
  tooltip: {
    download: 'Download import',
    start: '@:global.start',
    pause: '@:global.pause',
    edit: '@:global.edit',
    delete: '@:global.delete',
    restart: '@:global.restart',
  },
  channel: 'Channel',
  me: 'Me',
  system: 'System',
  go_to_products: 'Go to imported products',
  import_details: 'Import details',
  dry_run: 'Dry run',
  update_variants: 'Update variants',
  update_brands: 'Update brand',
  update_category: 'Update Category',
  update_title: 'Update title',
  multiple_variants: 'Multiple variants',
  select_source: 'Select source to import',
  attributes: 'Product attributes',
  assets: 'Product assets',
  trim_ean: 'Trim leading 0 values in EAN',
  variants_column: 'Variant column',
  language: 'Language',
  remove_leading_zeroes: 'Remove leading zeroes',
  started_successfully: 'Successfully started the import',
  start_failed: 'We were unable start the import. Please try again',
  import_mapping_tooltip: 'Please choose a mapping by restarting the import.',
  pending: 'Pending',
  completed: 'Completed',
  filesNotUploaded: 'You have selected files that must be uploaded before you can continue.',
  new: {
    title: 'New import',
    subtitle: 'Here you can create a new import.',
    uploaded_files: 'One file has been uploaded. | {count} files have been uploaded.',
    allowed_file_types: 'This channel only accepts specific file types: {types}',
    filesSuccessfullyUploaded: 'File(s) successfully uploaded',
    filesUploadedFailed: 'There was a problem uploading your file(s)',
  },
  edit: {
    title: 'Edit import',
    subtitle: 'Here you can edit an import.',
  },
  restart: {
    title: 'Restart import',
    subtitle: 'Here you can restart an import.',
  },
  error: {
    invalid_type: 'Please import an Excel, PDF or PNG file',
    supportedTypes:
      'Please import an file that is supported for this channel, Supported file type(s): {types}',
    rejectedFiles: 'One file has been rejected. | {count} files have been rejected.',
    selectSource: 'Atleast one source must be selected',
  },
  details: {
    title: 'Import details',
    dry_run: 'Dry run',
    include_data: 'Include data',
    include_assets: 'Include assets',
    channel: 'Channel',
    locale: 'Locale',
    mail_to: 'Mail to:',
    unknown_owner: 'Unknown owner',
  },
  details_modal: {
    title: 'Import Details',
    date: 'Date',
    errors: 'Errors',
    products: 'Products',
    imported_by: 'Imported by',
    importId: 'Import ID',
    channel: 'Channel',
    locale: 'Locale',
    mailed_to: 'Mailed to',
    data_included: 'Data is included',
    assets_included: 'Assets are included',
    import_log: 'Import log',
    import_statuses: 'Import statuses',
    gtin: 'GTIN',
    message: 'Message',
    status: 'Status',
    timestamp: 'Timestamp',
    raw_code: 'Raw Code',
    goto: 'Go to product',
    submission_id: 'Submission ID',
    unknown: 'Unknown',
    empty_table_title: 'There are no messages in the import log yet',
    empty_table_subtitle: 'Once a created import has  errors, the messages will show here',
    tab_product_errors: 'Product errors',
    tab_pipeline_statuses: 'Import statuses',
    ignore_product_status: 'Ignore product status',
  },
  //Old
  modals: {
    create_import: {
      category: 'Category',
      datamodel: 'Datamodel',
    },
  },
  import_scheduled: {
    title: 'Scheduled Import | Scheduled Imports',
    create: 'Create scheduled import',
    paused: 'Paused',
    running: 'Running',
    missing_channel: 'Missing channel',
    automated_import: 'automated import',
    next_import_at: 'Next import at',
    restarted_successfully: 'Successfully restarted the scheduled import.',
    restart_failed: 'Failed to restart the scheduled import. Please try again.',
    update_successfully: 'Successfully updated status of the scheduled import.',
    update_failed: 'Failed to update the scheduled import. Please try again.',
    mapping_tooltip: 'Please choose a mapping in the edit dialog.',
    frequency: 'Frequency',
    new: {
      title: 'New scheduled import',
      subtitle: 'Here you can create a new scheduled import.',
    },
    edit: {
      title: 'Edit scheduled import',
      subtitle: 'Here you can edit a scheduled import.',
    },
  },
  import_assets: {
    title: 'Import Assets',
    overwrites: {
      description:
        'You are about to attach {amountUploads} files to {amountProducts} products. Do you wish to continue?',
      option: 'Overwrite existing filenames',
      tooltip:
        'When selecting this option, duplicate filenames will be overwritten with the newly imported media.',
    },
    add_to_variants: {
      tooltip:
        'When selecting this option, the uploaded media will be added to all variants of a product.',
      option: 'Add media to all variants',
    },
    no_assets:
      "Bulk media imports let you automatically add product images, video's and documents to many products at once by matching the GTIN in the filename. You can also add the order in which the files should be imported. For example:",
    unmatched_assets: 'The following {amount} file(s) could not be matched automatically',
    selected: '{amount} selected files',
    select_all: 'Select all',
    deselect_all: 'Deselect all',
    delete_selected: 'Remove selected media',
    select_product: 'Select a product',
    match_product: 'Attach to product',
    matched_assets: 'The following products will be updated',
    match_failed: 'Could not match the uploads with any product',
    match_asset_failed: 'Failed to match assets to products. Please try again.',
    //Old
    action_name: 'Media import',
    bulk_title: 'Bulk Media Import',
    upload: {
      upload_assets: 'Upload media',
      add_more: 'Upload more',
    },
    import_assets: 'Import Media',

    snackbars: {
      saving_failed: 'Failed to import matched assets. Please try again.',
      saving_success: 'Successfully imported files.',
      matching_failed: 'Failed to match assets to products. Please try again.',
    },
  },
  table_headers: {
    status: 'Current status',
    saved_products: 'Saved products',
    channel: 'Channel',
    user: 'User',
    started_at: 'Started at',
    mapping: 'Mapping',
    importId: 'Import ID',
  },

  overwrite_data: 'Overwrite data',
  overwrite_data_tooltip: 'Overwrite existing data with new data',
  dialog: {
    emptyStateTitle: 'No files uploaded',
    emptyStateSubtitle: 'Upload files to attach to the import',
  },
} as const;

export default translationObject;
