<script setup lang="ts">
import { useService } from '@/general/composables/useService/useService';
import { tsKey } from '@/general/services/translations/translation.key';
import EmptyState from '@/general/ui/components/empty-state.vue';
import { importMappingItemsServiceKey } from 'retailer/modules/import-mapping-items/ts/keys/import-mapping-items.keys';
import { inject } from 'vue';
import { useRouter } from 'vue-router';
import { RetailerImportMappingItemsViewService } from '../ts/services/retailer-import-mapping-items-view.service';

const service = useService(importMappingItemsServiceKey, RetailerImportMappingItemsViewService)!;

const ts = inject(tsKey)!;

const router = useRouter();
</script>
<template>
  <div class="align-items-start flex flex-column gap-2 mb-3">
    <div class="align-items-center flex gap-2">
      <h3>{{ ts?.tModule('map_the_attributes') }}</h3>
      <Badge :value="service.amountOfSavedItems + '/' + service.amountOfAttributes" />
    </div>
    <p>{{ ts?.tModule('map_the_attributes_subtitles') }}</p>
  </div>

  <table class="mapping-items-table mb-4 w-full" cellspacing="0">
    <thead>
      <tr class="surface-50 text-left">
        <th class="border-1 border-200 p-4 pl-3 w-6">{{ ts?.tModule('import_source') }}</th>
        <th class="border-1 border-200 p-4 pl-3 w-6">
          {{ ts?.tModule('datamodel_attributes') }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(mapping, index) in service.mappingItems" :key="index" class="border-1 border-100">
        <td class="p-0">
          <InputText
            v-model="mapping.argument"
            type="text"
            class="border-0 border-noround focus:border-1 focus:border-primary focus:shadow-none pl-3 py-4 w-full"
            :placeholder="ts.tModule('sourceArgument')"
            @update:model-value="(value?: string) => value != null  && service.updateMappingItem(mapping, value)"
          />
        </td>
        <td
          v-if="mapping.attribute.overwrite_name"
          class="flex flex-column justify-content-start pl-5 py-3 surface-50 w-full"
        >
          <span class="pb-1 text-primary">
            {{ mapping.attribute.overwrite_name }}
          </span>
          <span class="text-color-secondary">
            {{ mapping.attribute.key }}
            <i
              v-tooltip.right="{ value: ts.tModule('originalAttributeName') }"
              class="mdi mdi-help-circle"
            ></i>
            /
            {{ mapping.attribute.type }}
          </span>
        </td>
        <td v-else class="flex flex-column justify-content-start pl-5 py-3 surface-50 w-full">
          <span class="pb-1">
            {{ mapping.attribute.key }}
          </span>
          <span class="text-color-secondary">
            {{ mapping.attribute.type }}
          </span>
        </td>
      </tr>
      <tr v-if="service.mappingItems.length === 0 && service.query == null && service.query === ''">
        <td colspan="2">
          <EmptyState
            :translation-service="ts"
            :icon-name="'imports'"
            :button-visible="true"
            :button-label="ts.tModule('emptyStateButton')"
            @clicked="router.push({ name: 'datamodels' })"
          />
        </td>
      </tr>
      <tr v-else-if="service.mappingItems.length === 0">
        <td colspan="2">
          <EmptyState
            :translation-service="ts"
            :empty-state-title="ts.tModule('noSearchResults')"
            :empty-state-subtitle="ts.tModule('noSearchResultsSubtitle')"
            :icon-name="'imports'"
            :button-label="ts.tModule('emptyStateButton')"
            @clicked="router.push({ name: 'datamodels' })"
          />
        </td>
      </tr>
    </tbody>
  </table>
  <ConfirmDialog group="delete-dialog" />
</template>
<style scoped lang="scss">
.mapping-items-table {
  border-collapse: collapse;

  tbody tr td:nth-child(odd) {
    border-right: 1px solid #dce5ef;
  }
}
</style>
