<script setup lang="ts">
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';
import AttributeFieldsRenderer from '@/general/ui/components/attribute-fields/attribute-fields-renderer.vue';
import ProductAttributeEmptyStates from '@/platforms/supplier/modules/products/components/empty-state/product-attribute-empty-states.vue';
import ProductAttributeSkeleton from '@/platforms/supplier/modules/products/components/skeletons/product-attribute-skeleton.vue';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { inject } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
withDefaults(
  defineProps<{
    selectedDatamodel?: Datamodel;
  }>(),
  {
    selectedDatamodel: undefined,
  },
);

//** Constants */
const productAttributeFieldService = inject(productAttributeFieldsServiceKey)!;

onBeforeRouteLeave((_, __, next) => {
  productAttributeFieldService.value.showOnlyEmptyFields = false;
  productAttributeFieldService.value.showDialog(next);
});
</script>
<template>
  <div>
    <ProductAttributeSkeleton
      v-if="
        productAttributeFieldService.loading ||
        !productAttributeFieldService.currentProduct ||
        productAttributeFieldService.isSaving
      "
    />
    <ProductAttributeEmptyStates
      v-if="!productAttributeFieldService.loading && !productAttributeFieldService.isSaving"
      :selected-datamodel="selectedDatamodel"
    />
    <AttributeFieldsRenderer
      v-if="
        productAttributeFieldService.attributeFields.length !== 0 &&
        !productAttributeFieldService.loading &&
        productAttributeFieldService.currentProduct &&
        !productAttributeFieldService.isSaving
      "
      :attribute-fields="productAttributeFieldService?.attributeFields"
    />
  </div>
</template>
