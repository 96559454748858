<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import { uniqueId } from 'lodash';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { AttributeTypeEnum } from 'platform-unit2-api/attributes';
import TabField from 'ui/components/attribute-fields/tabs-field/tabs-field.vue';
import { onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { AttributeFieldsRendererService } from './attribute-fields-renderer.service';
import GroupField from './group-field/group-field.vue';
defineProps<{
  attributeFields: AttributeField<any>[];
  advanceKey?: string;
}>();
const attributeFieldsRendererService = new AttributeFieldsRendererService();
const ts = new TranslationService('global', 'general');
const store = useStore();

onUnmounted(() => {
  store.dispatch('products/SET_VALIDATION_ERRORS', []);
});
</script>
<template>
  <div
    v-for="(attributeField, index) in attributeFields"
    :id="attributeField.attribute.key"
    :key="uniqueId(attributeField.attribute.id.toString() + index.toString() + (advanceKey ?? ''))"
    :class="
      attributeField.attribute.options?.group_size
        ? `col-${attributeField.attribute.options?.group_size}`
        : 'col-12'
    "
  >
    <div class="flex justify-content-between">
      <div class="flex justify-self-start">
        <strong class="mr-1">{{ attributeField.attribute.key }}</strong>
        <!-- The required attribute should be displayed if:
     1. The 'required' option is explicitly set to true in attributeField.attribute.options -> overwritten values.
     2. The 'required' attribute is true and the 'required' option is not explicitly set in attributeField.attribute.options. -> hasn't been overwritten explicitely -->
        <span
          v-if="
            attributeField.attribute?.options?.required === true ||
            (attributeField.attribute.required === true &&
              !attributeField.attribute?.options?.hasOwnProperty('required'))
          "
          class="font-bold text-red-500"
        >
          &ast;
        </span>
        <i
          v-if="attributeFieldsRendererService.helpText(attributeField.attribute)"
          v-tooltip.right="
            attributeFieldsRendererService.helpText(attributeField.attribute)
              ? {
                  value: attributeFieldsRendererService.helpText(attributeField.attribute),
                  disabled: false,
                  class: 'text-sm',
                }
              : { disabled: true }
          "
          class="cursor-pointer mdi mdi-help-circle-outline ml-2 text-xl"
        ></i>
        <a
          v-if="attributeFieldsRendererService.articleLink(attributeField.attribute)"
          class="ml-2 text-500"
          :href="attributeFieldsRendererService.articleLink(attributeField.attribute)"
          target="_blank"
        >
          <i class="cursor-pointer mdi mdi-file-document-outline text-xl"></i>
        </a>
      </div>
      <div
        v-if="attributeFieldsRendererService.hasOverwrittenKey(attributeField.attribute)"
        class="flex justify-self-end original-name"
      >
        <div>
          {{
            ts.tGlobal('original', {
              params: {
                original: attributeField.attribute.original_key,
              },
            })
          }}
        </div>
      </div>
    </div>
    <div
      v-if="
        attributeField.attribute.options.type !== AttributeTypeEnum.TAB_FIELD &&
        attributeField.attribute.options.type !== AttributeTypeEnum.GROUP_FIELD
      "
    >
      <section
        v-for="productAttributeField in attributeField.values || attributeField.children"
        :key="
        uniqueId(
          attributeField.attribute.id.toString() + (productAttributeField as ProductAttributeField<any>)?.id?.toString() + (productAttributeField.locale?.id ?? '').toString())
        "
        class="flex"
      >
        <Component
          :is="attributeFieldsRendererService.getComponent(attributeField)"
          :product-attribute-field="productAttributeField"
          :attribute-field="attributeField"
        />
        <slot name="afterField" :product-attribute-field="productAttributeField" />
      </section>
    </div>

    <GroupField
      v-if="attributeField.attribute.options.type === AttributeTypeEnum.GROUP_FIELD"
      :attribute-field="attributeField"
    />

    <TabField
      v-if="attributeField.attribute.options.type === AttributeTypeEnum.TAB_FIELD"
      :attribute-field="attributeField"
    />
  </div>
</template>

<style lang="scss" scoped>
.original-name {
  margin-right: 34px; //to align with end of input
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgb(139, 170, 201);
}
</style>
