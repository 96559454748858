const translationObject = {
  title: 'Activity | Activities',
  placeholder: 'Select an activity | Select activities',
  userUploaded: 'uploaded {count} image | has uploaded {count} images',
  userCreated: 'created a product',
  userDeletedStory: 'deleted a story',
  userRemovedImages: 'removed {count} image | removed {count} images',
  userChangedMedia: 'changed media',
  userUpdatedAttributes: 'updated {count} attribute | updated {count} attributes',
  userChangedProductStatus: 'changed product status',
  userAddedNewStory: 'added new story',
  mediaChanges: 'Media changes',
} as const;

export default translationObject;
