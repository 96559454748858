const translationObject = {
  title: 'Comment | Comments',

  commentInput: {
    placeholder: 'Start typing your message',
    suffix: 'Send',
  },
  errors: {
    sendFailed: 'We are unable to send the {name}. Please try again.',
  },

  empty: 'Someone has to be first. Start chatting now!',
} as const;

export default translationObject;
