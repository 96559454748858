<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref, watch, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

//New layout
import { KeycloakService } from '@/core/security/keycloak.service';
import { NavigationSidebarService } from '@/general/services/navigation-sidebar/navigation-sidebar.service';
import { User } from 'platform-unit2-api/users';
import SidebarContent from 'ui/layout/sidebar-content.vue';
import UnifiedNavigation from './unified-navigation.vue';

const currentUser = computed<User>(() => store.getters['users/currentUser']);

const store = useStore();
const route = useRoute();
const displayPaymentDialog = ref(false);
const navigationSidebarService = ref(NavigationSidebarService.getNavigationSidebarService());

const hasAccessToCrossNavigation = ref(false);

watch(
  () => store.getters['users/currentUser']?.id,
  () => {
    document.documentElement.style.fontSize =
      store.getters['users/currentUser']?.preferences?.scale.toString() + 'px';
  },
);

watch(
  () => route,
  () => {
    displayPaymentDialog.value = true;
  },
  {
    deep: true,
  },
);

onBeforeMount(async () => {
  const scale =
    useStore().getters['users/currentUser'] &&
    useStore().getters['users/currentUser'].preferences.scale;
  document.documentElement.style.fontSize = scale + 'px';

  hasAccessToCrossNavigation.value = KeycloakService.getInstance().hasAccessToResource('platform');
});

// #region : Sidebar */
const toggleSidebar = (value: boolean) => {
  navigationSidebarService.value.setNavigationStatus(!value);
};

//Collapse sidebar in small screen
const collapseAtSize = 800;
const windowWidth = ref(window.innerWidth);

onMounted(() => {
  window.addEventListener('resize', () => {
    windowWidth.value = window.innerWidth;
  });
});

watchEffect(() => {
  if (windowWidth.value <= collapseAtSize) {
    toggleSidebar(true);
  }
});
</script>

<template>
  <UnifiedNavigation v-if="hasAccessToCrossNavigation" />
  <aside
    v-if="currentUser"
    class="h-full"
    :class="{
      collapsed: !navigationSidebarService.isNavOpen,
      'cross-navigation': hasAccessToCrossNavigation,
    }"
  >
    <div
      v-if="windowWidth > collapseAtSize"
      class="collapse-button"
      @click="toggleSidebar(navigationSidebarService.isNavOpen)"
    >
      <i
        v-tooltip.right="{
          value: !navigationSidebarService.isNavOpen ? 'Expand menu' : 'Collapse menu',
        }"
        class="mdi mdi-chevron-left"
      />
    </div>

    <SidebarContent
      :is-collapsed="!navigationSidebarService.isNavOpen"
      :has-access-to-cross-navigation="hasAccessToCrossNavigation"
    />
  </aside>
</template>

<style scoped lang="scss">
aside {
  width: 27em;
  grid-area: sidebar;
  background-color: #f5f7fa;
  border-right: none;
  position: relative;
  transition: all $animation-duration ease;
  &.cross-navigation {
    width: 22em;
  }
  .collapse-button {
    width: 24px;
    aspect-ratio: 1;
    border: 1px solid #cad9e7;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -12px;
    bottom: 6%;
    z-index: 120;
    cursor: pointer;
    color: $link-color;
    transition: all $animation-duration ease;

    &:hover {
      border-color: $primary-color;
    }
  }

  &.collapsed {
    width: 60px;
    .collapse-button {
      transform: rotate(180deg);
    }
  }
}
</style>
