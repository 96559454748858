// Modifying theme components using design tokens
export const components = {
  avatar: {
    background: '{primary.color}',
    color: '{surface.0}',
  },
  autocomplete: {
    chipBorderRadius: '16px',
  },
  badge: {
    infoBackground: '{iris.50} !important',
    infoColor: '{gray.800}  !important',
    dangerBackground: '{red.600} !important',
    borderRadius: '16px',
  },
  button: {
    labelFontWeight: 'normal',
    outlined: {
      primaryHoverBackground: '{surface.50} !important',
    },
    text: {
      primaryHoverBackground: '{surface.50} !important',
      plainColor: '{gray.800}',
      dangerHoverBackground: '{red.50} !important',
      dangerColor: '{red.600} !important',
    },
    secondary: {
      background: '{surface.0} !important',
      color: '{primary.color} !important',
      borderColor: '{primary.color} !important',
      hoverBackground: '{azure.50} !important',
      hoverBorderColor: '{primaryColor} !important',
      hoverColor: '{primary.color} !important',
    },
    success: {
      background: '{green.600} !important',
      borderColor: '{green.600} !important',
      hoverBackground: '{green.500} !important',
      hoverBorderColor: '{green.500} !important',
    },
    danger: {
      background: '{red.600} !important',
      borderColor: '{red.600} !important',
      hoverBackground: '{red.500} !important',
      hoverBorderColor: '{red.500} !important',
    },
    fontSize: '14px !important',
  },
  card: {
    borderRadius: '3px',
    shadow: 'none',
  },
  checkbox: {
    borderColor: '{gray.400} !important',
    hoverBorderColor: '{gray.200} !important',
  },
  datatable: {
    headerCellBackground: '{surface.0}  !important',
    headerCellSelectedColor: '{primary.color}  !important',
    headerCellSelectedBackground: '{surface.0}  !important',
    rowSelectedBackground: '{gray.100}  !important',
    rowSelectedColor: '{text.color}  !important',
    columnTitleFontWeight: '400',
  },
  dataview: {
    borderColor: 'transparent',
    headerBorderColor: 'transparent',
    paginatorBottomBorderColor: 'transparent',
  },
  datepicker: {
    dropdownBackground: '{primary.color} !important',
    dropdownColor: '{surface.0} !important',
    dropdownHoverBackground: '{primary.hoverColor} !important',
    dropdownHoverColor: '{surface.0} !important',
  },
  dialog: {
    borderRadius: '3px',
  },
  inputnumber: {
    button: {
      background: '{primary.color} !important',
      color: '{surface.0} !important',
      hoverBackground: '{primary.hoverColor} !important',
      hoverColor: '{surface.0} !important',
      borderRadius: '0px !important',
    },
  },
  message: {
    borderRadius: '3px',
    info: {
      background: '{iris.50} !important',
      borderColor: '{iris.400} !important',
      color: '{text.color} !important',
    },
    success: {
      background: '{green.50} !important',
      borderColor: '{green.400} !important',
      color: '{text.color} !important',
    },
    warn: {
      background: '{orange.50} !important',
      borderColor: '{orange.400} !important',
      color: '{text.color} !important',
    },
    error: {
      background: '{red.50} !important',
      borderColor: '{red.400} !important',
      color: '{text.color} !important',
    },
  },
  multiselect: {
    chipBorderRadius: '16px',
  },
  paginator: {
    background: '{gray.50}',
    navButton: {
      borderRadius: '3px',
      selectedBackground: '{primary.color}',
      selectedColor: '{surface.0}',
    },
  },
  progressbar: {
    borderRadius: '3px',
  },
  tabs: {
    tab: {
      background: '{surface.0} !important',
      hoverBackground: '{surface.0} !important',
      hoverColor: '{primary.color} !important',
      borderWidth: '0 0 2px 0 !important',
    },
  },
  toast: {
    summaryFontWeight: '700',
    summaryFontSize: '14px',
    detailFontSize: '14px',
    borderWidth: '0.5px',
    borderRadius: '3px',
    errorBackground: '{red.100} !important',
  },
  toggleswitch: {
    disabled: {
      background: '{gray.200} !important',
    },
    handleDisabledBackground: '{surface.0} !important',
  },
};
