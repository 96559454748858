import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';

const create: TranslationObjectAction = {
  title: 'Create Channel',
  subTitle: 'Here you can create a new Channel.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete Channel',
};

const update: TranslationObjectAction = {
  title: 'Edit Channel',
  subTitle: 'Here you can edit a Channel.',
};

const translationObject = {
  title: 'Channel | Channels',
  placeholder: 'Select Channel | Select Channels',
  emptyStateSubtitle:
    'Start adding channels by contacting support to inquire about our available channels.',
  create: create,
  delete: deleteTranslation,
  update: update,

  credential_edit: {
    title: 'Credentials',
    subtitle: 'You can manage your credentials here',
    name: 'Name: ',
    type: 'Type: ',
    new: 'Enter new {field}',
  },
  channel_select: {
    label: 'Select a channel | Select the channels',
    placeholder: 'Select a channel | Select the channels',
  },
  authorization: {
    setup_success: 'Setup { entity } Integration Succeeded',
    setup_detail: 'Productsup is authorized to list your products.',
    setup_fail: 'Setup Amazon Integration Failed',
  },
  table: {
    name: 'Name',
    key: 'Key',
    type: 'Type',
    retailer: 'Retailer',
    template: 'Template',
  },
  fields: {
    type: 'Type',
    key: 'Key',
    name: 'Name',
    pixels: 'pixels',
    retailer: 'Select retailer',
    export_template: 'Export template',
    media_template: 'Media template',
    media_instructions: 'Media Instructions',
    file_per_product: 'File per product',
    export_resize_enabled: 'Export resize enabled',
    export_resize_width: 'Width',
    export_resize_height: 'Height',
    export_resize_format: 'Extension',
  },
  empty: 'No channels could be found',

  placeholders: {
    type: 'type',
    format: 'extension',
  },

  emptySearchStateSubTitle:
    'No channels found. Try to change the search query or contact support to add the corresponding channel.',
} as const;

export default translationObject;
