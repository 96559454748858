<script lang="ts" setup>
import { useService } from '@/general/composables/useService';
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import ActionsMenu from '@/general/ui/components/actions-menu.vue';
import EmptyState from '@/general/ui/components/empty-state.vue';
import LabeledTag from '@/general/ui/components/labeled-tag.vue';
import SvgIcon from '@/general/ui/components/svg-icon.vue';
import UploadVue from '@/general/ui/components/upload.vue';
import { formatDate } from '@/general/utils/format-date';
import { Upload } from 'platform-unit2-api/uploads';
import { DataTablePageEvent } from 'primevue/datatable';
import { DataViewPageEvent } from 'primevue/dataview';
import { DropdownChangeEvent } from 'primevue/dropdown';
import { FileUploadUploaderEvent } from 'primevue/fileupload';
import { SelectChangeEvent } from 'primevue/select';
import { ref } from 'vue';
import { assetViewServiceKey } from '../keys/asset-view-service.key';
import AssetCard from './asset-card.vue';

const service = useService(assetViewServiceKey, AssetViewService);
const ts = new TranslationService('supplier', 'media');
const actionMenu = ref();

const actionItems = ref([
  {
    label: ts.tModule(`actions.attach_labels`),
    icon: 'mdi mdi-plus-box-multiple',
    command: () => {
      service.attachAssetsModal.toggleVisible();
    },
  },
  {
    label: ts.tModule('actions.download_images'),
    icon: 'mdi  mdi-tray-arrow-down',
    command: () => {
      service.bulkDownload();
    },
  },
  {
    separator: true,
  },
  {
    label: ts.tModule('actions.delete_images'),
    icon: 'mdi mdi-delete-outline',
    class: 'delete',
    command: () => {
      service.bulkDeleteAction();
    },
  },
]);

const optionItems = (asset: Upload): Array<Object> => {
  const items = [
    {
      label: ts.tGlobal('edit'),
      icon: 'mdi mdi-pencil-outline',
      command: () => {
        service.redirectToUpdate(asset.id);
      },
    },
    {
      label: ts.tGlobal('download'),
      icon: 'mdi mdi-download-box-outline',
      command: () => {
        service.download(asset);
      },
    },
    {
      separator: true,
    },
    {
      label: ts.tGlobal('delete'),
      icon: 'mdi mdi-delete-outline',
      class: 'delete',
      command: () => {
        service.deleteAction(asset.id);
      },
    },
  ];

  return items;
};

const toggleActionMenu = (event: any): void => {
  actionMenu.value.toggle(event);
};
</script>

<template>
  <div class="flex-auto">
    <div class="h-full">
      <div v-if="service.assets.length === 0" class="empty-state-container">
        <EmptyState
          :empty-state-title="ts.tGlobal('emptyStateTitle', { choice: 2, entity: ts.title })"
          :translation-service="ts"
          :icon-name="'media'"
          :button-visible="false"
        >
          <template #body>
            <FileUpload
              ref="service.uploader"
              multiple
              auto
              class="mr-2"
              mode="basic"
              :choose-label="ts.tGlobal('upload')"
              :disabled="service.isUploading"
              :custom-upload="true"
              @uploader="(e: FileUploadUploaderEvent) => service.uploadAssets(e)"
            />
          </template>
        </EmptyState>
      </div>
      <DataView
        v-else
        lazy
        scrollable
        scroll-height="flex"
        :value="service.assets"
        :layout="service.layout.value"
        :paginator="service.total > (service.limit ?? 0)"
        :rows="service.limit"
        :first="service.first"
        :total-records="service.total"
        class="flex flex-column h-full"
        :class="{ 'bg-white': service.layout.value === 'list' }"
        data-key="id"
        @page="(e: DataViewPageEvent) => service.onPageChange(e.page)"
      >
        <template #header>
          <div class="flex justify-content-between">
            <div class="align-items-center flex justify-content-between">
              <p class="mr-2 text-400">
                <Button
                  text
                  plain
                  :label="service.renderSelectButtonLabel"
                  :icon="service.renderSelectedStatusIcon"
                  @click="service.manageSelectingAllFiles()"
                />
              </p>
              <Select
                :model-value="service.sortFields.find((f) => f.field === service.sortBy)"
                :options="service.sortFields"
                :option-value="''"
                option-label="label"
                @change="(e: DropdownChangeEvent) => service.onSortChange({sortField: e.value.field} as DataTablePageEvent)"
              />
            </div>
            <div v-if="!service.disabled" class="align-items-center flex justify-content-end">
              <Select
                v-model="service.limit"
                class="mr-2"
                :options="[16, 24, 52, 100, 252, 500]"
                @change="(e:SelectChangeEvent) => service.onPageChange(e.value)"
              />
              <SelectButton
                v-model="service.layout"
                :options="service.listTreeViewOptions"
                class="mr-2"
                option-label="value"
                data-key="value"
              >
                <template #option="slotProps"> <i :class="slotProps.option.icon" /> </template
              ></SelectButton>
              <Button
                :disabled="service.checkedAssets.length === 0"
                :model="actionItems"
                :label="ts.tGlobal('actions')"
                class="p-button-outlined"
                icon="mdi mdi-chevron-down"
                icon-pos="right"
                aria-haspopup="true"
                aria-controls="action_menu"
                @click="toggleActionMenu"
              />
              <Menu ref="actionMenu" :model="actionItems" popup />
            </div>
          </div>
        </template>

        <template v-if="!service.disabled" #list="slotProps">
          <div
            v-for="(item, index) in slotProps.items"
            :key="index"
            class="align-items-center col-12 flex pup-px-4"
          >
            <Checkbox v-model="service.checkedAssets" :value="item" />

            <div class="col-1">
              <div class="col-fixed">
                <UploadVue
                  v-if="item.public_thumbnail_url"
                  :upload="item"
                  preview
                  class="h-3rem pup-ml-3"
                />
                <SvgIcon
                  v-else
                  :filename="item.filename"
                  custom-style="height: 3rem"
                  class="pup-ml-3 w-full"
                />
              </div>
            </div>
            <div class="col-2 overflow-hidden text-overflow-ellipsis">
              <p class="inline w-full">
                {{ item.filename }}
              </p>
            </div>
            <div class="col-1"><LabeledTag :filename="item.filename" /></div>
            <div class="col-2 overflow-hidden text-overflow-ellipsis">
              <label class="font-bold mr-auto">{{ item?.asset_type?.name }}</label>
            </div>
            <div class="col-2 overflow-hidden text-overflow-ellipsis">
              {{ service.getFileSize(item) }}
            </div>
            <div class="col-3 overflow-hidden text-overflow-ellipsis">
              {{ formatDate(item.created_at) }}
            </div>
            <div class="col-1 flex justify-content-end pup-pr-8">
              <ActionsMenu :menu-items="optionItems(item)" />
            </div>
          </div>
        </template>

        <template #grid="slotProps">
          <div class="grid">
            <div
              v-for="(item, index) in slotProps.items"
              :key="index"
              class="col-12 flex flex-column lg:col-3 md:col-6 p-2 xl:col-3"
            >
              <AssetCard :image="item" />
            </div>
          </div>
        </template>
      </DataView>
      <ConfirmDialog group="media-assets" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.buttonset {
  width: 80px;
  :deep(.p-button.p-button-icon-only) {
    width: 40px;
    height: 33px;
  }
}

.empty-state-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}

.p-dataview :deep(.p-dataview-content) {
  flex: 1;
}
</style>
