import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';

const create: TranslationObjectAction = {
  title: 'Create user',
  subTitle: 'Here you can create a new user.',
};

const update: TranslationObjectAction = {
  title: 'Edit user',
  subTitle: 'Here you can edit an user.',
};

const translationObject = {
  title: 'User | Users',
  placeholder: 'Select user',

  create: create,
  update: update,

  emptyStateSubtitle:
    'Start by creating your first user. When a user is created it will show here.',
  emptySearchStateSubTitle: 'No users found. Try to change the search query or create a new user.',
} as const;

export default translationObject;
