<script setup lang="ts" generic="T">
import { AdvancedFieldPanel } from '@/general/services/attribute-fields/interfaces/advanced-field-tab.interface';
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';
import { TranslationService } from '@/general/services/translations/translation.service';
import { uniqueId } from 'lodash';
import Sortable from 'sortablejs';
import { inject, onMounted, ref, watch } from 'vue';
interface Props {
  listItems: AdvancedFieldPanel[];
  showHandle?: boolean;
}

interface Emits {
  (e: 'onDragEndCallback', event: Sortable.SortableEvent, items: AdvancedFieldPanel[]): void;
}

const props = withDefaults(defineProps<Props>(), {
  showHandle: true,
});

const ts = new TranslationService('general', 'components');

// This service has to be used conditionally since it might not be available outside of the PDP page
const productAttributeFieldsService = inject(productAttributeFieldsServiceKey);

const emit = defineEmits<Emits>();
const items = ref(props.listItems);
const htmlElementForDnD = ref();

function isInMissingFieldMode() {
  return productAttributeFieldsService?.value?.showOnlyEmptyFields ?? false;
}

onMounted(() => {
  new Sortable(htmlElementForDnD.value, {
    handle: '.handle',
    animation: 400,
    onUpdate: (event: Sortable.SortableEvent) => {
      // Update data array to maintain sorting
      if (event.newIndex != null && event.oldIndex != null) {
        items.value.splice(event.newIndex, 0, items.value.splice(event.oldIndex, 1)[0]);
      }

      emit('onDragEndCallback', event, items.value as AdvancedFieldPanel[]);
    },
  });
});

watch(
  () => props.listItems,
  () => {
    items.value = props.listItems;
  },
  { deep: true },
);
</script>
<template>
  <div ref="htmlElementForDnD">
    <!-- eslint-disable vue/valid-v-for -->
    <div
      v-for="(item, index) in items.filter((item) => !item.deleted)"
      :key="uniqueId(items.length.toString() + '_' + items.map((item) => item.deleted).toString())"
      class="flex my-2"
    >
      <!-- eslint-enable -->
      <span v-if="showHandle" :class="{ handle: !isInMissingFieldMode() }">
        <slot name="handle" :item="item" :item-index="index">
          <span
            v-tooltip.left="{
              value: ts.tModule('groupField.dragDisabled'),
              disabled: !isInMissingFieldMode(),
            }"
            :style="!isInMissingFieldMode() ? 'cursor: grab' : ''"
            class="align-items-center flex h-full mdi mdi-drag-vertical text-400 text-4xl"
          />
        </slot>
      </span>
      <slot :item="item" :item-index="index" />
    </div>
  </div>
</template>
