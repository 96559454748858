import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';

const create: TranslationObjectAction = {
  title: 'Create Brand',
  subTitle: 'Here you can create a new Brand.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete Brand',
};

const update: TranslationObjectAction = {
  title: 'Edit Brand',
  subTitle: 'Here you can edit a Brand.',
};

const translationObject = {
  title: 'Brand | Brands',
  placeholder: 'Select Brand | Select Brands',
  emptyStateSubtitle:
    'Start by creating your first brand. When a brand is created it will show here.',
  products_count: 'Products',
  completeness_score: 'Completeness score',

  emptySearchStateSubTitle:
    'No brands found. Try to change the search query or create a new brand.',

  create: create,
  delete: deleteTranslation,
  update: update,
} as const;

export default translationObject;
