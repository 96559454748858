<script setup lang="ts">
import { useService } from '@/general/composables/useService';
import { ScheduledImportViewService } from '@/general/services/overview-service/modules/scheduled-import-view.service';
import CronGenerator from 'ui/components/cron-generator.vue';
import { scheduledImportServiceKey } from '../../ts/keys/scheduled-import-service.key';

const service = useService(scheduledImportServiceKey, ScheduledImportViewService);
const setCron = (cron: string) => {
  service.partialObject.cron = cron;
};
</script>
<template>
  <div class="m-4">
    <CronGenerator
      v-if="service.partialObject.cron"
      :cron="service.partialObject.cron"
      @generated-crone="setCron"
      @disable-next-button="(val) => service.handleDisableNextButton(val)"
    />
  </div>
</template>
