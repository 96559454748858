<script setup lang="ts">
import { useService } from '@/general/composables/useService';
import { ExportMappingViewService } from '@/general/services/overview-service/modules/export-mapping/export-mapping-view.service';
import { tsKey } from '@/general/services/translations/translation.key';
import ActionsMenu from '@/general/ui/components/actions-menu.vue';
import EmptyState from '@/general/ui/components/empty-state.vue';
import DatamodelSelect from '@/general/ui/components/selects/datamodel-select.vue';
import ModuleSelect from '@/general/ui/components/selects/module-select.vue';
import { formatDate } from '@/general/utils/format-date';
import { ExportMapping } from 'platform-unit2-api';
import { DataTableFilterEvent } from 'primevue/datatable';
import { MenuItem } from 'primevue/menuitem';
import { inject } from 'vue';
import { exportMappingServiceKey } from '../services/keys/export-mapping.keys';
const service = useService(exportMappingServiceKey, ExportMappingViewService);

const ts = inject(tsKey)!;
const menuItems = (mapping: ExportMapping): MenuItem[] => [
  {
    label: ts.value.tGlobal('edit'),
    key: 'edit-export-mapping',
    command: () => service.navigateToMappingDetails(mapping),
    icon: 'mdi mdi-pencil',
  },
  {
    label: ts.value.tGlobal('duplicate'),
    command: () => service.openDuplicateDialog(mapping),
    icon: 'mdi mdi-content-copy',
  },
  {
    label: ts.value.tGlobal('delete'),
    command: () => service.deleteAction(mapping.id),
    icon: 'mdi mdi-delete',
    class: 'delete',
  },
];
</script>
<template>
  <div>
    <DataTable
      v-if="service != null && ts != null"
      v-model:filters="service.filters"
      row-hover
      removable-sort
      scrollable
      lazy
      scroll-height="flex"
      :paginator="service.limit ? (service.total ?? 0) > service.limit : false"
      :loading="service.isLoadingOverView"
      :rows="service.limit"
      :total-records="service.total"
      :first="service.first"
      :global-filter-fields="['datamodel.nama', 'module.name']"
      filter-display="menu"
      :value="service.exportMappings"
      @filter="(event: DataTableFilterEvent) => service.applyFilter(event)"
      @page="service.onPageChange($event.page)"
      @sort="service.onSortChange($event)"
      @row-click="service.navigateToMappingDetails($event.data)"
    >
      <template #empty>
        <EmptyState
          style="flex-grow: 1"
          :translation-service="ts"
          :icon-name="'export-mappings'"
          :button-visible="false"
        />
      </template>
      <Column
        field="datamodel.name"
        :header="ts.tGlobal('datamodel')"
        :show-filter-match-modes="false"
      >
        <template #filter="{ filterModel }">
          <DatamodelSelect v-model="filterModel.value" hide-label class="max-w-20rem" />
        </template>
      </Column>
      <Column
        field="module.name"
        :header="ts.tGlobal('module')"
        type="export"
        :show-filter-match-modes="false"
      >
        <template #filter="{ filterModel }">
          <ModuleSelect v-model="filterModel.value" hide-label class="max-w-20rem" />
        </template>
      </Column>
      <Column :header="ts.tModule('mappingType')">
        <template #body="{ data }">
          {{ data.owner ? ts.tModule('supported') : ts.tModule('unsupported') }}
        </template>
      </Column>
      <Column :header="ts.tModule('tableHeaders.lastUpdated')">
        <template #body="{ data }">
          {{ formatDate(data.updated_at) }}
        </template>
      </Column>
      <Column :header="ts.tGlobal('actions')">
        <template #body="{ data }">
          <ActionsMenu :menu-items="menuItems(data)" />
        </template>
      </Column>
    </DataTable>
  </div>
</template>
