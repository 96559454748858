const translationObject = {
  title: 'Language | Languages',
  placeholder: 'Select a language | Select multiple languages',

  locale_select: {
    label: 'Language',
    placeholder: 'Select a language | Select multiple languages',
    receiving_failed: 'Receiving locales failed.',
  },
} as const;

export default translationObject;
