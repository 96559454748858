const translationObject = {
  title: 'Incomplete product | Incomplete products',
  emptyStateTitle: 'All products are complete',
  emptyStateSubtitle: 'Products data is fully filled in!',

  productName: 'Product name',
  noEmptyFieldsModule:
    'There are no fields to display. This is likely because there are no export mappings for the selected channel,',
  contact_productsup: 'contact Productsup.',
  noUploads:
    'The presence of images affect the completeness score. Make sure that each product contains at least 1 image. Images can be added on the ',
  productsPage: 'product page',

  goToProduct: 'Go to product',
} as const;

export default translationObject;
